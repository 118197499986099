// import Keyboardist from 'keyboardist';

const DEACTIVATED = 'DEACTIVATED';

type Keyboardist = {
  startListening: () => void;
  stopListening: () => void;
};

export class KeyboardModes {
  modes: { [index: string]: Keyboardist } = {};

  currentMode: string = null;
  kbDeactivated = false;

  addMode(key: string, mode: Keyboardist) {
    const oldMode: Keyboardist = this.modes[key];
    if (oldMode && oldMode !== mode) {
      oldMode.stopListening();
    }
    this.modes[key] = mode;
  }

  stopListening() {
    for (const mode0 of Object.values(this.modes)) {
      const mode: Keyboardist = mode0;
      mode.stopListening();
    }
  }

  adjustKbHandlingForMode() {
    this.stopListening();
    if (this.currentMode && !this.kbDeactivated) {
      const mode: Keyboardist = this.modes[this.currentMode];
      mode.startListening();
    }
  }

  setKbDeactivated(value: boolean) {
    this.kbDeactivated = value;
    this.adjustKbHandlingForMode();
  }

  setMode(key: string) {
    this.currentMode = key;
    this.adjustKbHandlingForMode();
  }
}
