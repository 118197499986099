// open Fable.Core.DynamicExtensions
// open Precedence
// open BasicTypes
// open ElementList
// open ElementSort
// open ContentRoots
// open JSBoilerplate

import { Element } from './masala-lib/basic-types';
import { computed, makeObservable } from 'mobx';
import {
  ContentRootsBase,
  interleave,
  metadataOrder,
} from './masala-lib/editorial/content-roots/content-roots';
import { sortElements } from './masala-lib/elements/element-sort';
import { ElementList } from './masala-lib/elements/element-list';

// type IScriptEditorContentRoots =

// type ScriptEditorContentRoots0() as s0 =
export class ScriptEditorContentRoots extends ContentRootsBase {
  //
  stateVersion0 = 1;

  constructor() {
    super();
    makeObservable(this);
  }

  @computed({ keepAlive: true })
  get words() {
    return this.words0;
  }

  @computed({ keepAlive: true })
  get content(): ElementList {
    let result: Element[] = [];
    result.push(...this.sentences0);
    result.push(...this.wordGroups0);
    result.push(...this.structural0);
    sortElements(result);
    const translations = this.translations0;
    const translationsLookup = id => translations[id];
    result = interleave(result, translationsLookup, 1);

    const metadataToOrderFunc0 = metadataOrder.functions.precedenceProjection;
    const metadataToOrderFunc = (m: Element) => metadataToOrderFunc0(m.subKind);
    const metadata = [...this.metadataBlocks0].sort(metadataToOrderFunc);
    result = [...metadata, ...result];

    return new ElementList(result, this.episodeKey, null, this.words0, null, null, null);
  }

  @computed({ keepAlive: true })
  get translations() {
    return this.translations0;
  }

  @computed({ keepAlive: true })
  get stateVersion() {
    const touch = [this.words, this.content, this.translations];
    this.stateVersion0++;
    return this.stateVersion0;
  }
}

// let ScriptEditorContentRoots():IScriptEditorContentRoots = !< ScriptEditorContentRoots0()
