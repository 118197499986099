import { makeObservable, observable } from 'mobx';
import { FirestoreDocSet } from '../../firestore-db/firestore-doc-set.js';
import { DbPaths } from './db.js';
import { db } from '../../platform/firebase-init';

// TODO include rollback actions doc in future

export class VersionsDocSet extends FirestoreDocSet {
  constructor(key, listenMode = true) {
    super(key, listenMode);
    this.sentenceVersionsDoc = null;
    this.wordGroupVersionsDoc = null;
    this.structuralVersionsDoc = null;
    this.translationVersionsDoc = null;
    makeObservable(this, {
      sentenceVersionsDoc: observable.ref,
      wordGroupVersionsDoc: observable.ref,
      structuralVersionsDoc: observable.ref,
      translationVersionsDoc: observable.ref,
    });
  }

  clearAllDocs() {
    this.sentenceVersionsDoc = null;
    this.wordGroupVersionsDoc = null;
    this.structuralVersionsDoc = null;
    this.translationVersionsDoc = null;
  }

  get hasAllDocs() {
    const hasAll =
      this.sentenceVersionsDoc &&
      this.wordGroupVersionsDoc &&
      this.structuralVersionsDoc &&
      this.translationVersionsDoc;
    return hasAll;
  }

  copyTo(dest) {
    dest.sentenceVersionsDoc = this.sentenceVersionsDoc;
    dest.wordGroupVersionsDoc = this.wordGroupVersionsDoc;
    dest.structuralVersionsDoc = this.structuralVersionsDoc;
    dest.translationVersionsDoc = this.translationVersionsDoc;
  }

  watchAllDocs(cb) {
    const disposers = [];
    disposers.push(this.watchDocument(() => this.sentenceVersionsDoc, cb));
    disposers.push(this.watchDocument(() => this.wordGroupVersionsDoc, cb));
    disposers.push(this.watchDocument(() => this.structuralVersionsDoc, cb));
    disposers.push(this.watchDocument(() => this.translationVersionsDoc, cb));
    return () => {
      for (let d of disposers) d();
    };
  }

  listenFirestoreUpdates() {
    const disposers = [];
    const paths = new DbPaths(db, this.key);
    disposers.push(
      this.onSnapshot(paths.sentenceVersionsDocRef, doc => (this.sentenceVersionsDoc = doc.data()))
    );
    disposers.push(
      this.onSnapshot(
        paths.wordGroupVersionsDocRef,
        doc => (this.wordGroupVersionsDoc = doc.data())
      )
    );
    disposers.push(
      this.onSnapshot(
        paths.structuralVersionsDocRef,
        doc => (this.structuralVersionsDoc = doc.data())
      )
    );
    disposers.push(
      this.onSnapshot(
        paths.translationVersionsDocRef,
        doc => (this.translationVersionsDoc = doc.data())
      )
    );
    return () => {
      for (let d of disposers) d();
    };
  }
}
