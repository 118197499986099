// open BasicTypes
// open TranslationActions
// open AppRoot

import { mutationActions } from '../app-root';
import { TranslationActions } from '../masala-lib/editorial/models/actions/translation-actions';

// type TranslationActions1() =
export class ScriptEditorTranslationActions extends TranslationActions {
  get mutationActions() {
    return mutationActions;
  }
}

// let TranslationActions():ITranslationActions = !< TranslationActions1()
