import { makeObservable, observable } from 'mobx';
import { FirestoreDocSet } from '../../firestore-db/firestore-doc-set.js';
import { DbPaths } from './db.js';
import { db } from '../../platform/firebase-init';

export class ScriptDocSet extends FirestoreDocSet {
  constructor(key, listenMode = true) {
    super(key, listenMode);
    this.episodeMetadataDoc = null;
    this.verbatimDoc = null;
    this.structuralDoc = null;
    this.wordGroupsDoc = null;
    this.translationsDoc = null;
    this.metadataBlocksDoc = null;
    // this.threadsDoc = null;
    this.warningSuppressionsDoc = null;
    makeObservable(this, {
      episodeMetadataDoc: observable.ref,
      verbatimDoc: observable.ref,
      structuralDoc: observable.ref,
      wordGroupsDoc: observable.ref,
      translationsDoc: observable.ref,
      metadataBlocksDoc: observable.ref,
      // threadsDoc: observable.ref,
      warningSuppressionsDoc: observable.ref,
    });
  }

  clearAllDocs() {
    this.episodeMetadataDoc = null;
    this.verbatimDoc = null;
    this.structuralDoc = null;
    this.wordGroupsDoc = null;
    this.translationsDoc = null;
    this.metadataBlocksDoc = null;
    // this.threadsDoc = null;
    this.warningSuppressionsDoc = null;
  }

  get hasAllDocs() {
    return (
      this.episodeMetadataDoc &&
      this.verbatimDoc &&
      this.structuralDoc &&
      this.wordGroupsDoc &&
      this.translationsDoc &&
      this.metadataBlocksDoc &&
      // this.threadsDoc &&
      this.warningSuppressionsDoc
    );
  }

  verifyDocSet() {
    if (this.hasAllDocs) {
      this.status = 'COMPLETE';
    } else {
      this.status = 'IN_PROGRESS';
    }
  }

  copyTo(dest) {
    dest.episodeMetadataDoc = this.episodeMetadataDoc;
    dest.verbatimDoc = this.verbatimDoc;
    dest.structuralDoc = this.structuralDoc;
    dest.wordGroupsDoc = this.wordGroupsDoc;
    dest.translationsDoc = this.translationsDoc;
    dest.metadataBlocksDoc = this.metadataBlocksDoc;
    // dest.threadsDoc = this.threadsDoc;
    dest.warningSuppressionsDoc = this.warningSuppressionsDoc;
  }

  watchAllDocs(cb) {
    const disposers = [];
    disposers.push(this.watchDocument(() => this.episodeMetadataDoc, cb));
    disposers.push(this.watchDocument(() => this.verbatimDoc, cb));
    disposers.push(this.watchDocument(() => this.structuralDoc, cb));
    disposers.push(this.watchDocument(() => this.wordGroupsDoc, cb));
    disposers.push(this.watchDocument(() => this.translationsDoc, cb));
    disposers.push(this.watchDocument(() => this.metadataBlocksDoc, cb));
    // disposers.push(this.watchDocument(() => this.threadsDoc, cb));
    disposers.push(this.watchDocument(() => this.warningSuppressionsDoc, cb));
    return () => {
      for (let d of disposers) d();
    };
  }

  listenFirestoreUpdates() {
    const disposers = [];
    const paths = new DbPaths(db, this.key);
    disposers.push(
      this.onSnapshot(paths.metadataDocRef, doc => (this.episodeMetadataDoc = doc.data()))
    );
    // disposers.push(paths.metadataDocRef.onSnapshot(doc => this.episodeMetadataDoc = doc.data()));
    disposers.push(this.onSnapshot(paths.verbatimDocRef, doc => (this.verbatimDoc = doc.data())));
    // disposers.push(paths.verbatimDocRef.onSnapshot(doc => this.verbatimDoc = doc.data()));
    disposers.push(
      this.onSnapshot(paths.structuralDocRef, doc => (this.structuralDoc = doc.data()))
    );
    // disposers.push(paths.structuralDocRef.onSnapshot(doc => this.structuralDoc = doc.data()));
    disposers.push(
      this.onSnapshot(paths.wordGroupsDocRef, doc => (this.wordGroupsDoc = doc.data()))
    );
    // disposers.push(paths.wordGroupsDocRef.onSnapshot(doc => this.wordGroupsDoc = doc.data()));
    disposers.push(
      this.onSnapshot(paths.translationsDocRef, doc => (this.translationsDoc = doc.data()))
    );
    // disposers.push(paths.translationsDocRef.onSnapshot(doc => this.translationsDoc = doc.data()));
    disposers.push(
      this.onSnapshot(paths.metadataBlocksDocRef, doc => (this.metadataBlocksDoc = doc.data()))
    );
    // disposers.push(paths.metadataBlocksDocRef.onSnapshot(doc => this.metadataBlocksDoc = doc.data()));
    // disposers.push(this.onSnapshot(paths.threadsDocRef, doc => this.threadsDoc = doc.data()));
    // disposers.push(paths.threadsDocRef.onSnapshot(doc => this.threadsDoc = doc.data()));
    disposers.push(
      this.onSnapshot(
        paths.warningSuppressionsDocRef,
        doc => (this.warningSuppressionsDoc = doc.data())
      )
    );
    return () => {
      for (let d of disposers) d();
    };
  }
}
