import { MDBModal, MDBModalBody } from 'mdbreact';
import React from 'react';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { scriptEditorModel } from '../app-root';
import { WordGroupInspector } from './word-group-inspector.js';

class WordGroupInspectorDialogState {
  constructor() {
    this.open = false;
    makeObservable(this, {
      open: observable,
    });
  }
}

const dialogState = new WordGroupInspectorDialogState();

export function openWordGroupInspectorDialog() {
  dialogState.open = true;
  scriptEditorModel.setKbDeactivated(true);
}

export const WordGroupInspectorDialog = observer(() => {
  const toggleModal = () => {
    dialogState.open = !dialogState.open;
    scriptEditorModel.setKbDeactivated(dialogState.open);
  };

  return (
    <MDBModal size="xl" isOpen={dialogState.open} toggle={toggleModal}>
      <MDBModalBody>
        <WordGroupInspector />
      </MDBModalBody>
    </MDBModal>
  );
});
