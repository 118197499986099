// open Fable.Core
// open Fable.Core.JsInterop
// open BasicTypes
// open ScriptLoader
// open Mobx
// open Singletons
// open ScriptEditorContentRoots
// open JSBoilerplate
// open ElementTypes
// open KeyboardModes
// open MutationActions
// open StructuralActions
// open MetadataBlockActions
// open WordGroupActions
// open ConversationManager
// open TranslationActions
// open VerbatimActions
// open Versions
// open AlertMessages
// open Auth
// open Validations

import {
  configure as mobxConfigure,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import { ScriptEditorContentRoots } from './content-roots';
import { AlertMessages } from './masala-lib/alert-messages';
import { ElementId, notNull } from './masala-lib/basic-types';
import { Auth } from './masala-lib/editorial/db/auth';
import { MutationActions } from './masala-lib/editorial/db/mutation-actions';
import { ScriptLoader } from './masala-lib/editorial/db/script-loader';
import { MetadataBlockActions } from './masala-lib/editorial/models/actions/metadata-block-actions';
import { StructuralActions } from './masala-lib/editorial/models/actions/structural-actions';
import { TranslationActions } from './masala-lib/editorial/models/actions/translation-actions';
import { VerbatimActions } from './masala-lib/editorial/models/actions/verbatim-actions';
import { WordGroupActions } from './masala-lib/editorial/models/actions/word-group-actions';
import { ConversationManager } from './masala-lib/editorial/models/conversation-manager';
import { Versions } from './masala-lib/editorial/models/versions';
import { Validations } from './masala-lib/editorial/validations/validations';
import { EKinds } from './masala-lib/elements/element-kinds';
import { KeyboardModes } from './masala-lib/keyboard-mode';
import { ScriptEditorModel } from './script-editor-model';

import * as singletons from './singletons';

// mobxConfigure({|enforceActions="never"|}) // TODO consider if should enforce actions
mobxConfigure({ enforceActions: 'never' }); // TODO consider if should enforce actions

// [<ImportAll("./singletons.js")>]
// let singletons:obj = jsNative

// // TODO rename to just model???
// let scriptEditorModel:IScriptEditorModel = !< singletons?scriptEditorModel
export const scriptEditorModel: ScriptEditorModel = singletons.scriptEditorModel;
// let mutationActions:IMutationActions =  !< singletons?mutationActions
export const mutationActions: MutationActions = singletons.mutationActions;
// let structuralActions:IStructuralActions = !< singletons?structuralActions
export const structuralActions: StructuralActions = singletons.structuralActions;
// let metadataBlockActions:IMetadataBlockActions = !< singletons?metadataBlockActions
export const metadataBlockActions: MetadataBlockActions = singletons.metadataBlockActions;
// let wordGroupActions:IWordGroupActions = !< singletons?wordGroupActions
export const wordGroupActions: WordGroupActions = singletons.wordGroupActions;
// let conversationManager:IConversationManager = !< singletons?conversationManager
export const conversationManager: ConversationManager = singletons.conversationManager;
// let verbatimActions:IVerbatimActions = !< singletons?verbatimActions
export const verbatimActions: VerbatimActions = singletons.verbatimActions;
// let translationActions:ITranslationActions = !< singletons?translationActions
export const translationActions: TranslationActions = singletons.translationActions;
// let versions:IVersions = !< singletons?versions
export const versions: Versions = singletons.versions;
// let alertMessages:IAlertMessages = !< singletons?alertMessages
export const alertMessages: AlertMessages = singletons.alertMessages;
// let keyboardModes:IKeyboardModes = !< singletons?keyboardModes
export const keyboardModes: KeyboardModes = singletons.keyboardModes;
// let auth:IAuth = !< singletons?auth
export const auth: Auth = singletons.auth;

// let contentRoots = ScriptEditorContentRoots()
export const contentRoots = new ScriptEditorContentRoots();
// let validations = Validations(contentRoots)
export const validations = new Validations(contentRoots);

// // let mapContentOnSpanElements(elements: IElement [], words: IElement [], wordGroups: IElementList) =
// //     // HACKING
// //     let styleRenderer = StyleLayersRenderer()
// //     let wordList = {|elements=words|}
// //     for element in elements do
// //         let startIndex = element.wordAddress
// //         let endIndex = element.endWordAddress
// //         element.content <- renderWordRange Null styleRenderer !< wordList {starts=startIndex; ends=endIndex} wordGroups
// //         // element.content <- (words.[startIndex .. endIndex] |> String.concat " " )

// type AppRoot0() =
export class AppRoot {
  @observable.ref episodeKey = '';

  loader = new ScriptLoader();
  disposers: (() => void)[] = [];
  jumpId: ElementId = null;

  constructor() {
    makeObservable(this);
    this.disposers.push(
      reaction(
        () => this.loader.getStateVersion(),
        () => this.contentUpdated()
      )
    );
    this.disposers.push(
      reaction(
        () => conversationManager.getStateVersion(),
        () => this.contentUpdated()
      )
    );
  }

  jumpToId(jump0: string, ms: number) {
    if (jump0) {
      const jump: ElementId = jump0.includes(':') ? jump0 : Number(jump0);
      setTimeout(() => scriptEditorModel.setFocusedElementId(jump), ms);
    }
  }

  loadEpisode(episodeKey0, jumpId0: ElementId) {
    if (this.episodeKey === episodeKey0 && jumpId0) {
      this.jumpToId(<string>jumpId0, 20);
    } else {
      this.jumpId = jumpId0;
      this.loader.loadEpisode(episodeKey0, true);
      conversationManager.loadEpisode(episodeKey0, true);
    }
  }

  closeEpisode() {
    this.episodeKey = '';
    // todo: also reset the scriptEditorModal with a blank ElementList
    this.loader.closeEpisode();
    conversationManager.close();
  }

  contentUpdated() {
    console.log('appRoot.contentUpdated, status: ' + this.loader.getStatus());
    if (this.loader.getStatus() === 'COMPLETE') {
      console.log('COMPLETE: ' + this.loader.key);
      const newEpisode = this.episodeKey !== this.loader.key;
      this.episodeKey = this.loader.key;
      runInAction(() => {
        const t1 = Date.now();
        contentRoots.episodeKey = this.episodeKey;
        this.loader.docSet.copyTo(contentRoots);
        const content0 = contentRoots.content;
        // TODO: rename to "conversation", or can we somehow add as a getter to the element interface?
        const content = content0.joinWithIdMap('thread', conversationManager.obj, { messages: [] });

        const words = contentRoots.words;
        const sentences = content.getKindSubList(EKinds.SENTENCE);
        const wordGroups = content.getKindSubList(EKinds.WORD_GROUP);
        console.log('new compute content time: f' + (Date.now() - t1) + ' ' + t1);
        scriptEditorModel.editEnabled = contentRoots.editEnabled;
        scriptEditorModel.setElementList(content); // TODO would set state here in runInAction
        scriptEditorModel.setTranslationLanguage(contentRoots.translationLanguage);
        mutationActions.setEpisodeKey(this.episodeKey);
        if (newEpisode) {
          versions.lazyLoadVersions(this.episodeKey);
        }
        if (notNull(this.jumpId)) {
          this.jumpToId(<string>this.jumpId, 500);
          this.jumpId = null;
        }
      });
    }
  }
}

// let AppRoot():IAppRoot = !< AppRoot0()

// let appRoot = AppRoot() // TODO could put appRoot creation in singletons too??
export const appRoot = new AppRoot(); // TODO could put appRoot creation in singletons too??
// // appRoot.loadEpisode("el-show-editor2")
