import React from 'react';
import { observer } from 'mobx-react';
import { DiscussionSidepanel } from './discussion-sidepanel.js';
import { WordGroupEditor } from './word-group-editor';
import { scriptEditorModel } from './app-root';

export const ContextualSidepanel = observer(() => {
  let SidepanelComponent = null;
  let focusedElement = scriptEditorModel.focusedElement;
  let warning = scriptEditorModel.focusedElementValidatorWarning;
  if (focusedElement) {
    if (focusedElement.kind === 'WORD_GROUP') {
      SidepanelComponent = WordGroupEditor;
    } else {
      SidepanelComponent = DiscussionSidepanel;
    }
  }

  const content = scriptEditorModel.elements; // TODO remove this? don't need pass stuff to app level components can get from app level models?

  return SidepanelComponent ? (
    <SidepanelComponent element={focusedElement} content={content} warning={warning} />
  ) : null;
});
