import { observable, makeObservable } from 'mobx';

export class ResilientRowCursor {
  constructor(getRowKey) {
    this.getRowKey = getRowKey;
    this.cursor = null;
    this.getRowKey = getRowKey;
    this.rows = [];
    makeObservable(this, {
      cursor: observable.ref,
    });
  }

  setRows(rows, reset = false) {
    const oldRows = this.rows;
    this.rows = rows;
    if (!rows || !rows.length) {
      this.cursor = null;
      return;
    }
    const cursor = this.cursor || { index: 0 };
    const oldIndexValid = cursor.index < rows.length;
    let index = -1;
    if (cursor.key) {
      if (oldIndexValid && this.getRowKey(rows[cursor.index]) === cursor.key) {
        return;
      }
      index = rows.findIndex(row => this.getRowKey(row) === cursor.key);
      if (index !== -1) {
        this.cursor = { index, key: this.getRowKey(rows[index]) };
        return;
      }
    }
    // TODO this test is kind of bogus, really want to know if filter changed
    if (Math.abs(rows.length - oldRows.length) > 2 || !oldIndexValid) {
      this.resetCursor();
      return;
    }
    this.cursor = { index: cursor.index, key: this.getRowKey(rows[cursor.index]) };
  }

  resetCursor() {
    if (this.rows.length > 0) {
      this.cursor = { index: 0, key: this.getRowKey(this.rows[0]) };
    } else {
      this.cursor = null;
    }
  }

  setCursor = cursor => {
    // case only index value check index is valid create index and with key and assign
    if (!cursor.key) {
      if (cursor.index < this.rows.length) {
        this.cursor = { index: cursor.index, key: this.getRowKey(this.rows[cursor.index]) };
        return;
      } else {
        this.resetCursor();
        return;
      }
    }
    // case index and key, if consistent copy over and assign to cursor else change to only key
    if (cursor.index) {
      if (
        cursor.index < this.rows.length &&
        cursor.key === this.getRowKey(this.rows[cursor.index])
      ) {
        this.cursor = { ...cursor };
        return;
      }
    }
    const index = this.rows.findIndex(row => this.getRowKey(row) === cursor.key);
    if (index !== -1) {
      this.cursor = { index, key: this.getRowKey(this.rows[index]) };
      return;
    }
    this.resetCursor();
  };

  getCursor = () => {
    return this.cursor;
  };
}
