import { EKinds } from './element-kinds';

export const elementKindAnchorDirection = {
  [EKinds.CHAPTER_TITLE]: 1,
  [EKinds.PASSAGE_HINT]: 1,
  [EKinds.SPEAKER_LABEL]: 1,
  [EKinds.CHAPTER_SUMMARY]: 1,
  [EKinds.CHAPTER_COMPLETE]: 1,
  [EKinds.CULTURAL_NOTE]: 1,
  [EKinds.PARAGRAPH_BREAK]: -1,
};
