// open BasicTypes
// open StructuralActions
// open AppRoot

import { alertMessages, mutationActions, scriptEditorModel } from '../app-root';
import { StructuralActions } from '../masala-lib/editorial/models/actions/structural-actions';

// type StructuralActions1() =
export class ScriptEditorStructuralActions extends StructuralActions {
  get mutationActions() {
    return mutationActions;
  }
  get content() {
    return scriptEditorModel.elements;
  }
  get alertMessages() {
    return alertMessages;
  }
}

// let StructuralActions():IStructuralActions = !< StructuralActions1()
