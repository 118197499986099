import { ElementId } from '../../../basic-types';
import { MutationActions } from '../../db/mutation-actions';

// [<AbstractClass>]
export abstract class TranslationActions {
  getTranslationElementId(id: ElementId, translationLanguage: string) {
    return `TRANSLATION:${id}/${translationLanguage}`;
  }

  addUpdate(id: ElementId, translationLanguage: string, translation: string) {
    this.mutationActions.addUpdateTranslation(id, translationLanguage, translation);
    return this.getTranslationElementId(id, translationLanguage);
  }

  revert(versionObject: any) {
    this.mutationActions.revertTranslation(versionObject);
  }

  abstract get mutationActions(): MutationActions;
}
