// open BasicTypes
// open MetadataBlockActions
// open AppRoot

import { mutationActions } from '../app-root';
import { MetadataBlockActions } from '../masala-lib/editorial/models/actions/metadata-block-actions';

// type MetadataBlockActions1() =
export class ScriptEditorMetadataBlockActions extends MetadataBlockActions {
  get mutationActions() {
    return mutationActions;
  }
}

// let MetadataBlockActions():IMetadataBlockActions = !< MetadataBlockActions1()
