// import { parseJWScript } from "./masala-lib/editorial/jw-script/jwscript-parser.fs.js";
// import { parseAndSaveToFirestore } from "./masala-lib/lib.fs.js";
// import {initChaat} from './masala-lib/editorial/chaat/chaat-crud.fs.js';

const masalaServer = 'https://masala-v1-server.jiveworld.com';

// async function parseAndSaveFromUrl(url) {
//   let script = await fetch(`${masalaServer}/jw_script_text_from_url?url=${url}`);
//   script = await script.text();
//   parseAndSaveToFirestore("blort27", script);
// }

// async function testInitChaat(key) {
//   await initChaat(key);
// }

// invoked by pressing '2' from a script editor view
export function test1() {
  console.log('test.js-test1');
  // parseAndSaveFromUrl('https://docs.google.com/document/d/1IJpQfAC7Daz1czXAe7TfbnY3G_mEltFbskJnC_dAePA/edit')
  // parseAndSaveFromUrl('https://docs.google.com/document/d/1z4FQtqwJEzWiH5_Sq2jrhOE7zJj_8btwjDHXK_q7SEk/edit');
  // parseAndSaveFromUrl('https://docs.google.com/document/d/1IJpQfAC7Daz1czXAe7TfbnY3G_mEltFbskJnC_dAePA/edit');
  //   testInitChaat('je-cola-space-jwimp-6-1');
  console.log('test hook succeeded');
}
