import { makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { domIdToElementId, getKindFromId, IdRange } from '../../basic-types';
import { makeAdhocWordRange } from '../../elements/ad-hoc-word-range';
import { EKinds } from '../../elements/element-kinds';
import { ElementList } from '../../elements/element-list';
import { randomString } from '../../utils';
import { StyleLayer } from './style-painting/style-layers';

import { renderView as renderWordRangeSelectView } from './word-range-select-view.js';

@observer
export class WordRangeSelect extends React.Component<any> {
  setWordRangeSelection: (id: IdRange) => void;
  getWordRangeSelection: () => IdRange;

  domScope: string = randomString(8);

  constructor(props) {
    super(props);
    this.setWordRangeSelection = props.setWordRangeSelection;
    this.getWordRangeSelection = props.getWordRangeSelection;
  }

  givenStyleLayers(): Map<string, StyleLayer> {
    return this.props.givenStyleLayers;
  }

  wordGroups(): ElementList {
    return this.props.wordGroups;
  }

  domainWords(): ElementList {
    return this.props.domainWords;
  }

  displayWordRange(): IdRange {
    return this.props.displayWordRange;
  }

  displayWordIndexRange() {
    return this.domainWords().idRangeToIndexRange(this.displayWordRange());
  }

  handleWordClick(event: MouseEvent, id) {
    const currentSelection = this.getWordRangeSelection();
    if (event.getModifierState('Shift') && currentSelection) {
      this.setWordRangeSelection({ starts: currentSelection.starts, ends: id });
    } else {
      this.setWordRangeSelection({ starts: id, ends: id });
    }
  }

  handleClick(event: MouseEvent) {
    // TODO figure out how to not strong coerce
    const domId = (event.target as any).id;
    const id = domIdToElementId(this.domScope, domId);
    const kind = getKindFromId(id);
    if (kind === EKinds.WORD) {
      this.handleWordClick(event, id);
    }
  }

  wordSelectionStyleLayer() {
    const currentSelection = this.getWordRangeSelection();
    let els = currentSelection ? [makeAdhocWordRange(currentSelection, this.domainWords())] : [];
    const elements = new ElementList(els, null, null, this.domainWords(), null, null, null);
    return {
      styles: ['word-range-selection'],
      domScope: this.domScope,
      supportWordRanges: true,
      elements: elements,
      stylesString: null,
    };
  }

  styleLayers() {
    const result: Map<string, StyleLayer> = new Map(this.givenStyleLayers().entries());
    result.set('wordSelection', this.wordSelectionStyleLayer());
    return result;
  }

  render() {
    return renderWordRangeSelectView({
      wordRange: this.displayWordIndexRange(),
      domScope: this.domScope,
      styleLayers: this.styleLayers(),
      wordGroups: this.wordGroups(),
      domainWords: this.domainWords(),
      onClick: e => this.handleClick(e),
    });
  }
}
