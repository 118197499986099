import React from 'react';
import { WordRangeSelect } from './masala-lib/editorial/ui/word-range-select';
import { MDBBtn, MDBInput } from 'mdbreact';
import { ConversationView } from './ui/conversation-view';

export function renderView(wordGroupEditor) {
  const getSelectedWordRange = () => wordGroupEditor.getSelectedWordRange();
  const setSelectedWordRange = selection => wordGroupEditor.setSelectedWordRange(selection);
  const givenStyleLayers = new Map();
  const noteName = 'Definition'; // TODO
  const anonymous = false; // TODO

  const element = wordGroupEditor.wordGroup;

  return (
    <>
      <div className="right-sidepanel-header">Word Group Editor</div>
      <div className="word-group-editor">
        <div className="word-group-edit-form">
          <WordRangeSelect
            getWordRangeSelection={getSelectedWordRange} // TODO consistent naming
            setWordRangeSelection={setSelectedWordRange} // TODO consistent naming
            displayWordRange={wordGroupEditor.sentenceWordRange}
            domainWords={wordGroupEditor.domainWords}
            givenStyleLayers={givenStyleLayers}
            wordGroups={wordGroupEditor.wordGroups}
          />
          <div>
            <div style={{ display: 'flex' }}>
              <div style={{ width: 'max-content' }}></div>
              <div style={{ flexGrow: 1 }}>
                <MDBInput
                  label={noteName}
                  disabled={anonymous}
                  type="text"
                  style={{ width: '100%' }}
                  className="word-group-note-edit"
                  value={wordGroupEditor.noteTextValue}
                  onChange={e => wordGroupEditor.handleNoteTextChange(e)}
                  // onKeyDown={(e) => this.handleSuggestionInputKeypress(e)} TODO
                  ref={element => (wordGroupEditor.noteInputEl = element)}
                />
              </div>
            </div>
            <MDBBtn
              color="info"
              size="sm"
              disabled={anonymous || !wordGroupEditor.valuesChanged}
              onClick={() => wordGroupEditor.handleWordGroupChangeSubmit()}
            >
              Update
            </MDBBtn>
            <MDBBtn
              color="info"
              size="sm"
              disabled={anonymous}
              onClick={() => wordGroupEditor.handleWordGroupDeleteSubmit()}
            >
              Delete
            </MDBBtn>
          </div>
        </div>
        <ConversationView element={element} />
      </div>
    </>
  );
}
