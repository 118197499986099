import { ElementId, ElementKind } from '../../../basic-types';
import { randomString } from '../../../utils';
import { MutationActions } from '../../db/mutation-actions';

// [<AbstractClass>]
export abstract class MetadataBlockActions {
  create(kind: ElementKind): ElementId {
    const newId = `METADATA_BLOCK:${randomString(12)}`; // TODO factor to mutation actions?
    this.mutationActions.addUpdateMetadataBlock({
      subKind: kind,
      id: newId,
      content: '',
    });

    return newId;
  }

  updateContent(id: ElementId, content: string) {
    this.mutationActions.updateContentMetadataBlock(id, content);
  }

  remove(id: ElementId) {
    this.mutationActions.removeMetadataBlock(id);
  }

  abstract get mutationActions(): MutationActions;
}
