import { computed, makeObservable, observable, reaction } from 'mobx';
import { Element, isEmpty, NO_INDEX } from '../../basic-types';
import { EKinds, structuralKinds } from '../../elements/element-kinds';

export type FilterTerm = {
  inputText: string;
  parsed: boolean;
  kind: string;
  props: any;
  // }
};

export type FilterDef = {
  kind: string;
  isFlag: boolean;
  canonicalText: (term: FilterTerm) => string;
  parse: (term: FilterTerm) => void;
  func: (term: FilterTerm) => (el: Element) => boolean;
  // }
};

export enum BaseFilterKinds {
  WORD_GROUP = 'WORD_GROUP',
  VOCAB = 'VOCAB',
  TRICKY = 'TRICKY',
  SIC = 'SIC',
  SENTENCE = 'SENTENCE',
  UNFILLED = 'UNFILLED',
  STRUCTURAL = 'STRUCTURAL',
  OPEN = 'OPEN',
  WARNING = 'WARNING',
  ASSIGNED = 'ASSIGNED',
  AREPARTICIPANT = 'AREPARTICIPANT',
  MENTION = 'MENTION',
  BY = 'BY', // TODO includes from?
}

export function regexTermParser(kind: string, pattern: RegExp) {
  const re = new RegExp(pattern);
  const parse = (term: FilterTerm) => {
    const m = term.inputText.match(re);
    if (m) {
      term.kind = kind;
      term.parsed = true;
      term.props = m.groups;
    }
  };
  return parse;
}

export const wordGroupFilter = {
  kind: BaseFilterKinds.WORD_GROUP,
  isFlag: true,
  canonicalText: t => '#group ',
  parse: regexTermParser(BaseFilterKinds.WORD_GROUP, /#group/),
  func: (term: FilterTerm) => (el: Element) => el.kind === EKinds.WORD_GROUP,
  // }
};

export const vocabFilter = {
  kind: BaseFilterKinds.VOCAB,
  isFlag: true,
  canonicalText: t => '#vocab ',
  parse: regexTermParser(BaseFilterKinds.VOCAB, /#vocab/),
  func: (term: FilterTerm) => (el: Element) => el.subKind === EKinds.VOCAB,
  // }
};

export const trickyFilter = {
  kind: BaseFilterKinds.TRICKY,
  isFlag: true,
  canonicalText: t => '#tricky ',
  parse: regexTermParser(BaseFilterKinds.TRICKY, /#tricky/),
  func: (term: FilterTerm) => (el: Element) => el.subKind === EKinds.TRICKY,
  // }
};

export const sicFilter = {
  kind: BaseFilterKinds.SIC,
  isFlag: true,
  canonicalText: t => '#sic ',
  parse: regexTermParser(BaseFilterKinds.SIC, /#sic/),
  func: (term: FilterTerm) => (el: Element) => el.subKind === EKinds.SIC,
  // }
};

export const sentenceFilter = {
  kind: BaseFilterKinds.SENTENCE,
  isFlag: true,
  canonicalText: t => '#sentence ',
  parse: regexTermParser(BaseFilterKinds.SENTENCE, /#sentence/),
  func: (term: FilterTerm) => (el: Element) => el.kind === EKinds.SENTENCE,
  // }
};

export const structuralFilter = {
  kind: BaseFilterKinds.STRUCTURAL,
  isFlag: true,
  canonicalText: t => '#structural ',
  parse: regexTermParser(BaseFilterKinds.STRUCTURAL, /#structural/),
  func: (term: FilterTerm) => (el: Element) => structuralKinds.includes(el.kind),
  // }
};

export const unfilledFilter = {
  kind: BaseFilterKinds.UNFILLED,
  isFlag: true,
  canonicalText: t => '#unfilled ',
  parse: regexTermParser(BaseFilterKinds.UNFILLED, /#unfilled/),
  func: (term: FilterTerm) => (el: Element) =>
    el.kind === EKinds.WORD_GROUP && el.subKind === EKinds.VOCAB && isEmpty(el.content.note),
  // }
};

export const openFilter = {
  kind: BaseFilterKinds.OPEN,
  isFlag: true,
  canonicalText: t => '#open ',
  parse: regexTermParser(BaseFilterKinds.OPEN, /#open/),
  func: (term: FilterTerm) => (el: Element) =>
    (<any>el).thread.withMessages && !(<any>el).thread.resolved,
  // }
};

// TODO filter by comments/actions only "last" or "all"

const username = term => term.props.username ?? null;

// // TODO already duplicated, move and create more string utilities
// [<Emit("$0.indexOf($1)")>]

export const byFilter = {
  kind: BaseFilterKinds.BY,
  isFlag: false,
  canonicalText: t => '@' + t.props.username + ' ',
  parse: regexTermParser(BaseFilterKinds.BY, /@(?<username>\w*)/),
  func: (term: FilterTerm) => (el: Element) => {
    const name: string = username(term);
    return name ? el.author.includes(name) : false;
  },
  // }
};

const functionListAND =
  (funcs: ((a: any) => boolean)[]) =>
  (v: any): boolean => {
    if (funcs.length === 0) {
      return false;
    } else {
      return funcs.findIndex(f => !f(v)) === NO_INDEX;
    }
  };

export class FilterModel {
  filterDefs: FilterDef[];
  filterDefMap: Map<string, FilterDef> = new Map();

  @observable.ref uiText = '';

  @observable.ref inputFilterTerms: FilterTerm[] = [];

  @observable.ref filterFunction = (e: Element) => false;

  disposers: (() => void)[] = [];

  allFlagKindsList: string[];

  allFlagKindsSet: Set<string>;

  termKindsCanonicalOrder: string[];

  termSortComparer: (a: FilterTerm, b: FilterTerm) => number;

  constructor(filterDefs0: FilterDef[]) {
    this.filterDefs = filterDefs0;
    this.allFlagKindsList = this.filterDefs.filter(def => def.isFlag).map(def => def.kind);
    this.allFlagKindsSet = new Set(this.allFlagKindsList);
    this.termKindsCanonicalOrder = this.filterDefs.map(def => def.kind);

    this.termSortComparer = (t1: FilterTerm, t2: FilterTerm) => {
      const t1index = this.termKindsCanonicalOrder.findIndex(kind => t1.kind === kind);

      // TODO factor with above instead
      const t2index = this.termKindsCanonicalOrder.findIndex(kind => t2.kind === kind);
      return t1index - t2index;
    };
    for (const def of this.filterDefs) {
      this.filterDefMap.set(def.kind, def);
    }
    makeObservable(this);
    this.disposers.push(
      reaction(
        () => this.uiText,
        () => this.computeInputFilterTerms()
      )
    );
    this.disposers.push(
      reaction(
        () => this.inputFilterTerms,
        () => this.computeUiText()
      )
    );
    this.disposers.push(
      reaction(
        () => this.filterKey,
        () => this.computeFilterFunction()
      )
    );
  }

  rawTermsFromText(text: string): FilterTerm[] {
    const paddedText = ' ' + text; // TODO for some reason cannot find an delimiter at start of string unless do this
    const delimiters = /@|#/g;
    const matches = [...paddedText.matchAll(delimiters)];
    const lookBehindAt = (offset: number) => offset > 0 && paddedText[offset - 1] === '@';
    let offsets = matches.filter(m => !lookBehindAt(m.index)).map(m => m.index - 1);
    offsets = [0, ...offsets, text.length];
    const result: FilterTerm[] = [];
    for (let s0 = 0; s0 < offsets.length; s0++) {
      const e0 = s0 + 1;
      const s = offsets[s0];
      const e = offsets[e0];
      result.push({ inputText: text.slice(s, e), parsed: false, kind: null, props: null });
    }
    return result;
  }

  parseTerm(term: FilterTerm) {
    for (const def of this.filterDefs) {
      def.parse(term);
      if (term.parsed) {
        break;
      }
    }
  }

  termCanonicalText(term: FilterTerm): string {
    // TODO for flags express relationship between kinds and canonical rep with bidirectional mapping and also use for do matching?
    if (term.parsed) {
      const def = this.filterDefMap.get(term.kind);
      return def.canonicalText(term);
    } else {
      return null;
    }
  }

  termUiText(term: FilterTerm): string {
    return term.inputText || this.termCanonicalText(term);
  }

  termFilterFunction(term: FilterTerm): (el: Element) => boolean {
    // TODO for flags use some kind of mapping not if statements? (use if statments for others)
    if (term.parsed) {
      const def = this.filterDefMap.get(term.kind);
      return def.func(term);
    } else {
      throw Error('not parsed in termFilterFunction');
    }
  }

  computeInputFilterTerms() {
    const terms: FilterTerm[] = this.rawTermsFromText(this.uiText);
    for (const term of terms) {
      this.parseTerm(term);
    }
    this.inputFilterTerms = terms;
  }

  computeUiText() {
    const termStrings = this.inputFilterTerms.map(term => this.termUiText(term));
    this.uiText = termStrings.join('');
  }

  @computed
  get parsedFilterTerms(): FilterTerm[] {
    return this.inputFilterTerms.filter(term => term.parsed);
  }

  @computed
  get activeFlags(): Set<string> {
    const flagList = this.parsedFilterTerms
      .filter(term => this.allFlagKindsSet.has(term.kind))
      .map(term => term.kind);
    return new Set(flagList);
  }

  @computed
  get filterKey(): string {
    const terms = this.parsedFilterTerms.slice().sort(this.termSortComparer);
    return terms.map(term => this.termCanonicalText(term)).join('');
  }

  computeFilterFunction() {
    const filters = this.parsedFilterTerms.map(term => this.termFilterFunction(term));
    this.filterFunction = functionListAND(filters);
  }

  setFlag(flag: string, value: boolean) {
    if (value) {
      this.inputFilterTerms = [
        ...this.inputFilterTerms,
        { inputText: null, kind: flag, parsed: true, props: null },
      ];
    } else {
      this.inputFilterTerms = this.inputFilterTerms.filter(t => t.kind !== flag);
    }
  }
}
