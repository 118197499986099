import { ScriptEditorMutationActions } from './mutation-actions.js';
import { ScriptEditorModel } from './script-editor-model';
import { KeyboardModes } from './masala-lib/keyboard-mode';
import { ScriptEditorStructuralActions } from './models/structural-actions';
import { ScriptEditorMetadataBlockActions } from './models/metadata-block-actions';
import { ScriptEditorWordGroupActions } from './models/word-group-actions';
import { ConversationManager } from './masala-lib/editorial/models/conversation-manager';
import { ScriptEditorVerbatimActions } from './models/verbatim-actions';
import { ScriptEditorTranslationActions } from './models/translation-actions';
import { Versions } from './masala-lib/editorial/models/versions';
import { AlertMessages } from './masala-lib/alert-messages';
import { Auth } from './masala-lib/editorial/db/auth';

export const auth = new Auth();
export const alertMessages = new AlertMessages();
export const mutationActions = new ScriptEditorMutationActions();
export const scriptEditorModel = new ScriptEditorModel();
export const keyboardModes = new KeyboardModes();
export const structuralActions = new ScriptEditorStructuralActions();
export const metadataBlockActions = new ScriptEditorMetadataBlockActions();
export const wordGroupActions = new ScriptEditorWordGroupActions();
export const conversationManager = new ConversationManager();
export const verbatimActions = new ScriptEditorVerbatimActions();
export const translationActions = new ScriptEditorTranslationActions();
export const versions = new Versions();
