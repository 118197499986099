import React from 'react';
import { computed, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { expr } from 'mobx-utils';
import { ResilientRowCursor } from './resilient-row-cursor';

const RESERVE_ROWS_COUNT = 3;

class ScrollingRowsImpl extends React.Component {
  constructor(props) {
    super(props);
    this.scrollTop = 0;
    this.scrollWrapperEl = null;
    this.getCursor = null;
    this.handleScroll = this.handleScroll.bind(this);
    this.getCursor = this.props.getCursor;
    this.tableEl = React.createRef();
    makeObservable(this, {
      scrollTop: observable,
      scrollWrapperEl: observable.ref,
      wrapper: computed,
      allRows: computed,
      rowHeight: computed,
      firstRenderedRowIndex: computed,
      rows: computed,
    });
  }

  setScrollWrapperRef(element) {
    if ((!this.wrapper && element) || (this.wrapper && element && this.wrapper !== element)) {
      this.scrollWrapperEl = element;
    }
  }

  get wrapper() {
    return this.scrollWrapperEl;
  }

  get renderingRowsHeight() {
    // TODO not right?
    return this.wrapper ? this.wrapper.parentNode.offsetHeight + 200 : 0;
  }

  // TODO decide if need computed to minimize rerenders
  get allRows() {
    return this.props.allRows;
  }

  get rowHeight() {
    return this.props.rowHeight;
  }

  get firstRenderedRowIndex() {
    return Math.max(0, Math.floor(this.scrollTop / this.rowHeight) - RESERVE_ROWS_COUNT);
  }

  get pixelOffset() {
    return this.rowHeight * this.firstRenderedRowIndex;
  }

  get lastRenderedRowIndex() {
    return this.firstRenderedRowIndex + Math.ceil(this.renderingRowsHeight / this.rowHeight);
  }

  get rows() {
    return this.allRows.slice(this.firstRenderedRowIndex, this.lastRenderedRowIndex);
  }

  // focusRow(/*blah*/) {
  //   //TODO take out this.virtualizedRows if not implement this method because only place would be used
  // }

  handleScroll() {
    this.scrollTop = Math.max(this.wrapper.scrollTop, 0);
  }

  render() {
    return (
      <div className="GridContainer" ref={this.tableEl}>
        <div
          className="GridScrollWrapper"
          onScroll={() => this.handleScroll()}
          ref={el => this.setScrollWrapperRef(el)}
        >
          <RowsScrollDummy
            rows={this.allRows}
            rowHeight={this.rowHeight}
            refEl={this.scrollDummyEl}
          />
          {
            <VirtualizedRows
              // TODO note this props reference will cause rerender regardless of above computeds
              {...this.props}
              // ref={this.virtualizedRows}
              rows={this.rows}
              allRows={this.allRows}
              startIndex={this.firstRenderedRowIndex}
              offset={this.pixelOffset}
            />
          }
        </div>
      </div>
    );
  }
}
export const ScrollingRows = observer(ScrollingRowsImpl);

class RowsScrollDummy extends React.Component {
  // TODO develop mobx pattern to react to changes in props values without reacting to changes to props reference

  shouldComponentUpdate(nextProps) {
    return nextProps.rows !== this.props.rows;
  }

  getHeight() {
    const { rows, rowHeight } = this.props;
    return rows.length * rowHeight + 'px';
  }

  render() {
    return (
      <div
        className="GridScrollDummy"
        style={{ height: this.getHeight() }}
        ref={this.props.refEl}
      ></div>
    );
  }
}

const keys = {
  // ENTER: 13,
  UP: 38,
  DOWN: 40,
  // ESC: 27
};

// TODO think pure component not compatible with observer
class VirtualizedRowsImpl extends React.PureComponent {
  disposers = [];

  constructor(props) {
    super(props);
    this.getRowClassName = this.getRowClassName.bind(this);
    this.onGlobalKeyDown = this.onGlobalKeyDown.bind(this);
    // TODO reaction for activeRow change calls onActiveRowChange in props
    // TODO reaction to computed allRows that sets activerow based on key or selected not y
  }

  get activeRow() {
    return this.props.getCursor();
  }

  get keyEventElement() {
    const { keyEventElement } = this.props;
    if (keyEventElement && keyEventElement()) {
      return keyEventElement();
    }
    return null;
  }

  componentDidMount() {
    if (this.keyEventElement) {
      this.keyEventElement.addEventListener('keydown', this.onGlobalKeyDown, false);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.keyEventElement) {
      this.keyEventElement.removeEventListener('keydown', this.onGlobalKeyDown, false);
      this.keyEventElement.addEventListener('keydown', this.onGlobalKeyDown, false);
    }
  }

  componentWillUnmount() {
    if (this.keyEventElement) {
      this.keyEventElement.removeEventListener('keydown', this.onGlobalKeyDown, false);
    }
  }

  get allRows() {
    return this.props.allRows;
  }

  get rowCount() {
    return this.allRows.length;
  }

  moveBy(offset) {
    if (!this.activeRow) {
      return;
    }
    let { index } = this.activeRow;
    index += offset;
    this.setActiveRow({ index });
  }

  moveUp() {
    this.moveBy(-1);
  }

  moveDown() {
    this.moveBy(1);
  }

  setActiveRow({ index, key = null }) {
    if (index < 0) {
      index = 0;
      key = null;
    }
    if (index >= this.rowCount) {
      index = this.rowCount - 1;
      key = null;
    }
    if (!key) {
      key = this.props.getRowKey(this.allRows[index]);
    }
    this.props.setCursor({ index, key });
  }

  // doFocus(rowInfo, event) {
  //   if (this.props.onFocusAction) {
  //     this.props.onFocusAction(rowInfo, event);
  //   }
  // }

  onGlobalKeyDown(e) {
    // TODO use keyboardist instead
    if (e.target !== this.keyEventElement) {
      return;
    }
    if (this.activeRow) {
      if (e.keyCode === keys.UP || e.code === 'KeyK') {
        e.preventDefault();
        this.moveUp();
      }

      if (e.keyCode === keys.DOWN || e.code === 'KeyJ') {
        e.preventDefault();
        this.moveDown();
      }

      // if (e.keyCode === keys.ENTER) {
      //   this.doFocus(this.activeRow, e);
      // }
    }
  }

  onRowClick(row, index, e) {
    // TODO
    this.setActiveRow({ index });
    // if (this.props.onRowClick) {
    //   this.props.onRowClick(row);
    // }
  }

  // onRowDoubleClick(row, y, e) {
  //   // TODO invoke focus method also?
  //   this.setActiveRow({y});
  // }

  getRowClassName({ row, y }) {
    return 'GridRow' + (this.activeRow?.key === this.props.getRowKey(row) ? ' GridRowActive' : '');
  }

  calculatePosition() {
    return this.props.offset + 'px';
  }

  renderBody() {
    // TODO currently will rerender if any props changed, desired behavior?
    const { rows, startIndex, rowComponent } = this.props;

    let body;

    if (rows.length) {
      body = rows.map((row, i) => {
        const y = startIndex + i;
        return (
          <Row
            y={y}
            key={this.props.getRowKey(row)}
            row={row}
            getClassName={this.getRowClassName}
            onClick={event => this.onRowClick(row, y, event)}
            // onDoubleClick={(event) => this.onRowDoubleClick(row, y, event)}
            height={this.props.rowHeight}
          >
            {rowComponent(row)}
          </Row>
        );
      });
    } else {
      body = <div className="GridPlaceholder">{/*{this.props.placeholder}*/}</div>;
    }
    return body;
  }

  render() {
    return (
      <div
        className="VirtualizedRows"
        style={{
          transform: `translate3d(0, ${this.calculatePosition()}, 0)`,
        }}
      >
        {this.renderBody()}
      </div>
    );
  }
}
const VirtualizedRows = observer(VirtualizedRowsImpl);

const Row = observer(props => {
  const {
    y,
    row,
    onClick,
    // onDoubleClick,
    getClassName,
    height,
    children,
  } = props;

  const className = expr(() => getClassName({ row, y }));

  return (
    <div
      className={className}
      onClick={onClick}
      // onDoubleClick={onDoubleClick}
      style={{
        height: height + 'px',
      }}
    >
      {children}
    </div>
  );
});

// TODO is all below code just testing, rip out?
const rows = [];

for (let i = 0; i < 1000; i++) {
  rows.push({
    id: i,
    text: 'First name ' + i,
  });
}

export function DataTable(props) {
  const { allRows } = props;
  const rowValue = row => {
    return <div>{row.text}</div>;
  };
  const cursor = new ResilientRowCursor(row => row.id);
  cursor.setRows(allRows);

  return (
    <div className="DataTable">
      <ScrollingRows
        getCursor={cursor.getCursor}
        setCursor={cursor.setCursor}
        rowComponent={rowValue}
        allRows={allRows}
        getRowKey={row => row.id}
        rowHeight={30}
      />
    </div>
  );
}

export function TestDataTable(props) {
  return (
    <div>
      <DataTable allRows={rows} />
    </div>
  );
}
