import { elementIdToDomId, IndexRange, NO_INDEX } from '../../basic-types';
import { ElementList } from '../../elements/element-list';
import { StyleLayersRenderer } from './style-painting/style-layers';

const wordOpening = '<span class="word ';
const wordClosing0 = '</span>';
const wordClosing = ' ' + wordClosing0;
const transitionClosing = wordClosing0;
const wordGroupOpening = '<span class="word-group ';
const wordGroupClosing = '</span> ';

const wordGroupKindStyles = {
  VOCAB: ' vocab ',
  TRICKY: ' tricky ',
  SIC: ' sic ',
  // |}
};

export function renderWordRange(
  domScope,
  styleOverlays: StyleLayersRenderer,
  wordElements: ElementList,
  indexRange: IndexRange,
  wordGroups: ElementList
) {
  let html = '';
  const words = wordElements.elements;
  const wordGroupIntervals = wordGroups.wordIntervals;
  let wordGroupStartIndex = NO_INDEX;
  let wordGroupEnds = false;
  let nestLevel = 0;
  for (let i = indexRange.starts; i <= indexRange.ends; i++) {
    const word = words[i];
    const text: string = (word as any).text; // TODO
    const wordId = word.id;
    const domId = elementIdToDomId(domScope, wordId);
    const wordStyles: string = styleOverlays.getStyleForWordAddress(i);
    wordGroupStartIndex = wordGroupIntervals.startsAt(i);
    if (wordGroupStartIndex !== NO_INDEX) {
      if (nestLevel > 0) {
        wordGroupStartIndex = NO_INDEX;
      }
      nestLevel++;
    }
    const wordGroupEndsIndex = wordGroupIntervals.endsAt(i);
    wordGroupEnds = wordGroupEndsIndex !== NO_INDEX;
    if (wordGroupEnds) {
      if (nestLevel > 1) {
        wordGroupEnds = false;
      }
      nestLevel--;
    }

    // TODO same logic as script editor but concerned about bounds, check
    if (wordGroupStartIndex !== NO_INDEX) {
      html += wordGroupOpening;
      const wordGroup = wordGroups.elements[wordGroupStartIndex];
      const wordGroupId = wordGroup.id;
      const wordGroupKindStyle: string = wordGroupKindStyles[wordGroup.subKind];
      const wordGroupStyles = ' ' + styleOverlays.getStyleForElement(wordGroupId);
      html += wordGroupKindStyle + wordGroupStyles + '" id="' + wordGroupId + '" >';
    }
    html += wordOpening + wordStyles + '" id="' + domId + '" >';
    html += text;
    html += wordGroupEnds ? transitionClosing + wordGroupClosing : wordClosing;
  }
  return html;
}
