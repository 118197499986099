import React from 'react';
import { lineEditorConfigurations } from './line-editor-configurations.js';
import { ScriptLineEditor } from './script-line-editor.js';
import { renderWordRange } from './masala-lib/editorial/ui/word-range-render';
import { EKinds } from './masala-lib/elements/element-kinds';

export function SentenceView({ element, editor }) {
  const styleLayersRenderer = editor.styleLayersRenderer;
  const model = editor.model;
  const wordGroups = model.wordGroups;
  const startIndex = element.wordAddress;
  const endIndex = element.endWordAddress;
  const sentenceHTML = renderWordRange(
    null,
    styleLayersRenderer,
    model.words,
    { starts: startIndex, ends: endIndex },
    wordGroups
  );
  // TODO JS constants for element kinds - include F# file?
  // TODO implement content queries
  const sentenceNum = model.sentences.getIndex(element.id) + 1;
  const prefix = `<span>[${sentenceNum}] </span>`;
  const style = lineEditorConfigurations[EKinds.SENTENCE].style;
  return <div className={style} dangerouslySetInnerHTML={{ __html: prefix + sentenceHTML }} />;
}

export function SentenceLineEditor(props) {
  const { element, editor } = props;
  const model = editor.model;
  const sentenceNum = model.sentences.getIndex(element.id) + 1;
  const prefix = `[${sentenceNum}] `;
  props = { prefix, ...props };
  return <ScriptLineEditor {...props} />;
}

function ScriptElement({ element, onClick, getIsEditingId, setActive, editor }) {
  const styleLayersRenderer = editor.styleLayersRenderer;
  const kind = element.kind;
  const config = lineEditorConfigurations[kind];
  const isEditing = getIsEditingId(element.id);
  let LineComponent = null;
  if (isEditing) {
    LineComponent = config.editorComponent || ScriptLineEditor;
  } else {
    LineComponent = config.viewComponent || ScriptLineEditor;
  }
  // const isEditor = isEditing || (LineComponent === ScriptLineEditor);
  const renderedStyles = 'editor-line ' + styleLayersRenderer.getStyleForElement(element.id);
  return (
    <div className={renderedStyles} id={element.id} onClick={onClick}>
      <LineComponent
        element={element}
        getIsEditingId={getIsEditingId}
        setActive={setActive}
        editor={editor}
      />
    </div>
  );
}

function versionedElementKey(element) {
  // TODO move to utils in F#
  return element.id + ':' + element.timestamp;
}

export function renderView(editor) {
  const model = editor.model;
  let elements = model.visibleElements;
  elements = elements && elements.elements;
  elements = elements || [];
  let touch = model.editingId;
  const getIsEditingId = id => {
    return model.editingId === id;
  };

  const setActive = (lineEditor, isActive) => editor.setActiveLineEditor(lineEditor, isActive);

  const handleLineClick = (event, id) => {
    if (event.target.id) {
      editor.handleLineClick(event, event.target.id);
    } else {
      editor.handleLineClick(event, id);
    }
  };

  // const handleLineDoubleClick = (event, id) => {
  //   // TODO simplify it handleLineDoubleClick does not require params?
  //   if (event.target.id) {
  //     editor.handleLineDoubleClick(event, event.target.id);
  //   } else {
  //     editor.handleLineDoubleClick(event, id);
  //   }
  // }

  return (
    <div className="editor" id="editorId" onScroll={event => editor.handleScroll(event)}>
      {elements.map(el => (
        <ScriptElement
          key={versionedElementKey(el)}
          element={el}
          onClick={event => handleLineClick(event, el.id)}
          // onDoubleClick={(event) => handleLineDoubleClick(event, el.id)}
          getIsEditingId={getIsEditingId}
          setActive={setActive}
          editor={editor}
        />
      ))}
    </div>
  );
}
