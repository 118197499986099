import { MutationActions } from './masala-lib/editorial/db/mutation-actions.js';
import { auth, alertMessages } from './app-root';

export class ScriptEditorMutationActions extends MutationActions {
  // constructor() {
  //   super();
  // }

  get auth() {
    return auth;
  }

  get alerts() {
    return alertMessages;
  }
}
