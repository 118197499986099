import { makeObservable, observable, runInAction } from 'mobx';
import { isEmpty, isNil } from 'lodash';
import { WebClient } from '@slack/web-api';
import firebase from 'firebase';
import { randomString, epochSecondsFloat } from '../../utils';
import { deploymentConfig } from '../../deployment-config';
import { db, fieldValueDelete, fieldValueArrayUnion } from '../../platform/firebase-init';
import { loaderStatus } from '../../firestore-db/constants';
import { theUserManager } from './the-user-manager';

type CollectionReference = firebase.firestore.CollectionReference;
type QuerySnapshot = firebase.firestore.QuerySnapshot;

const { NOT_INITIATED, IN_PROGRESS, COMPLETE } = loaderStatus;

type DisposerFn = () => void;

export interface CommentDTO {
  id?: string; // COMMENT:[random 12 char string]
  content?: string; // the actual comment/message text
  author?: string; // user.id
  edited?: boolean; // true if modified after initial post
  timestamp?: number; // time of post or last update (seconds since unix epoch)
  // slackId: string // slack message id. presence indicates comment has been mirror to slack

  // these parent references are stuffed in during the load
  episodeKey?: string;
  elementId?: string;
}

export interface CommentDictionary {
  [index: string]: CommentDTO;
}

export interface ConversationDTO {
  id?: string; // [episodeKey]-[elementId]
  episodeKey?: string;
  elementId?: string;
  // comments: any // map of id -> CommentDTO
  comments?: CommentDictionary;
  commentList?: CommentDTO[];
  participants?: string[]; // list of user.id's
  assignee?: string; // user.id
  resolved?: boolean;
  slackThreadId?: string;
  timestamp?: number; // last updated
  // volumeSlug: string

  // stuffed in after load
  withMessages?: boolean;
}

export const linkUrl = (conversation: ConversationDTO) => {
  const baseUrl = deploymentConfig.scriptEditorUrl;
  const linkPath = `/episodes/${conversation.episodeKey}/${conversation.elementId}`;
  return `${baseUrl}${linkPath}`;
};

interface ConstructorOptions {
  episode?: string; // episodeKey
  assignee?: string; // user.id
  participant?: string; // user.id
}

export class ConversationManager {
  collectionRef: CollectionReference;
  listenMode = true;
  disposers: DisposerFn[] = [];

  episodeKey: string;
  assignee: string;
  participant: string;

  @observable.ref
  status: string = NOT_INITIATED;

  @observable.ref
  list: ConversationDTO[] = [];

  @observable.ref
  obj: ConversationDTO = {};

  @observable.ref
  stateVersion = 1;

  constructor(options: ConstructorOptions = {}) {
    // TODO: confirm if this is actually compatible - was needed by masala-server/node build
    this.collectionRef = db.collection('Element__conversations') as unknown as CollectionReference;
    // two modes for this loader, one fetching by episode and the other by user
    this.episodeKey = options.episode;
    this.assignee = options.assignee; // user.id
    this.participant = options.participant; // user.id
    makeObservable(this);
  }

  loadEpisode(key: string, listenMode = true) {
    this.episodeKey = key;
    this.listenMode = listenMode;
    this.load();
  }

  // raw: true -> return null if no conversation record yet or not yet initialized
  //      false -> returns placeholder empty record if missing or not yet initialized
  getConversation(elementId: string, raw = false, episodeKey = this.episodeKey): ConversationDTO {
    const result = this.list.find(
      data => data.episodeKey === episodeKey && data.elementId === elementId
    );
    if (!raw && isNil(result)) {
      return { comments: {}, commentList: [] };
    }
    return result;
  }

  // private
  buildDocId({ episodeKey, elementId }) {
    // todo: give more thought to doc id's
    return `${episodeKey}-${elementId}`;
  }

  // private
  docRef({ episodeKey, elementId }) {
    const docId = this.buildDocId({ episodeKey, elementId });
    return this.collectionRef.doc(docId);
  }

  // async createConversation(conversationData) {
  //   console.log(`createConversation: ${JSON.stringify(conversationData)}`);
  //   const docId = this.buildDocId(conversationData);

  //   const {episodeKey, elementId, participants = [], assignee = null, resolved = false, comments = {}} = conversationData;
  //   const timestamp = epochSecondsFloat();
  //   const createData = {id: docId, episodeKey, elementId, participants, assignee, resolved, comments, timestamp};

  //   const docRef = this.collectionRef.doc(docId);
  //   await docRef.set(createData);
  // }

  async deleteConversation(elementId: string, episodeKey = this.episodeKey) {
    const docId = this.buildDocId({ episodeKey, elementId });
    const docRef = this.collectionRef.doc(docId);
    await docRef.delete();
  }

  async postComment(
    elementId: string,
    author: string,
    content: string,
    episodeKey = this.episodeKey
  ) {
    // console.log(`postComment: ${JSON.stringify({episodeKey, elementId, author, content})}`);
    const commentId = `COMMENT:${randomString(12)}`; // todo: someday revisit guid strategy?

    const timestamp = epochSecondsFloat();
    const commentData: CommentDTO = { id: commentId, author, content, timestamp };

    const resolved = content.includes('/resolve') || content.includes('(resolved)'); // hack to test logic

    const docId = this.buildDocId({ episodeKey, elementId });
    const conversationData: ConversationDTO = {
      id: docId,
      episodeKey,
      elementId,
      comments: { [commentId]: commentData },
      resolved,
      timestamp,
    };

    // note, this only matches against current query.
    // desired handling of resolved conversations needs product level input.
    // currently they will have createdAt and assignee reset, but retain prior comments.
    const existingConversation = this.getConversation(elementId, true, episodeKey);
    if (isNil(existingConversation)) {
      conversationData.assignee = null;
    }

    const joinedParticipants = [author];

    // todo: parsing and validation needs to be much more robust here
    if (content.includes('@')) {
      const words = content.split(' ');
      const match = words.find(word => word.includes('@'));
      const mentioned = match.replace('@', '');

      if (isEmpty(mentioned)) {
        conversationData.assignee = null;
      } else {
        joinedParticipants.push(mentioned);
        conversationData.assignee = mentioned;
      }
    }
    // todo: review the need to cast this
    conversationData.participants = fieldValueArrayUnion(
      ...joinedParticipants
    ) as unknown as string[];

    const docRef = this.docRef({ episodeKey, elementId });
    await docRef.set(conversationData, { merge: true });

    const linkPath = `/episodes/${episodeKey}/${elementId}`;
    console.log(`before await slack notif`);
    const existingThreadId = isEmpty(existingConversation?.slackThreadId)
      ? null
      : existingConversation?.slackThreadId;
    const threadId = await this.notifySlack({
      threadId: existingThreadId,
      topic: docId,
      linkPath,
      author,
      content,
    });
    console.log(`after await slack notif`);
    if (!existingConversation?.slackThreadId) {
      await docRef.update({ slackThreadId: threadId });
    }
  }

  // beware, if threads exist which have been first posted to a different slack channel
  // then the thread id must be reset before they'll show on the new channel
  async resetSlackThreadId(elementId: string, episodeKey = this.episodeKey) {
    const docRef = this.docRef({ episodeKey, elementId });
    await docRef.update({ slackThreadId: fieldValueDelete() });
  }

  async updateComment(
    elementId: string,
    commentId: string,
    content: string,
    episodeKey = this.episodeKey
  ) {
    const contentPath = `comments.${commentId}.content`;
    const timestampPath = `comments.${commentId}.timestamp`;
    const editedPath = `comments.${commentId}.edited`;
    const docRef = this.docRef({ episodeKey, elementId });
    await docRef.update({
      [contentPath]: content,
      [editedPath]: true,
      [timestampPath]: epochSecondsFloat(),
    });
  }

  async deleteComment(elementId: string, commentId: string, episodeKey = this.episodeKey) {
    console.log(`deleteComment: ek: ${episodeKey}, ei: ${elementId}, ci: ${commentId}`);
    const timestamp = epochSecondsFloat();
    const commentPath = `comments.${commentId}`;
    const docRef = this.docRef({ episodeKey, elementId });
    await docRef.update({ [commentPath]: fieldValueDelete(), timestamp });
  }

  /** @return CollectionReference or DocumentReference of data to be loaded */
  loadReference(options) {
    console.log(
      `ConversationManager.loadReference: episode: ${this.episodeKey}, participant: ${this.participant}, assignee: ${this.assignee}`
    );
    const baseQuery = this.collectionRef.where('resolved', '==', false);
    if (this.episodeKey) {
      return baseQuery.where('episodeKey', '==', this.episodeKey);
    } else {
      if (this.assignee) {
        return baseQuery.where('assignee', '==', this.assignee);
      } else {
        if (this.participant) {
          return baseQuery.where('participants', 'array-contains', this.participant);
        } else {
          // throw Error("ConversationsManager filter scope expected")
          return this.collectionRef.where('id', '==', '_none_');
        }
      }
    }
  }

  /**
   * @snapshot QuerySnapshot or DocumentSnapshot
   * @return model data
   */
  snapshotToData(snapshot: QuerySnapshot): ConversationDTO[] {
    // todo: at some point this will deserve some optimization
    const result = [];
    const objResult = {};
    snapshot.forEach(documentSnapshot => {
      const data: ConversationDTO = documentSnapshot.data();
      data.commentList = data.comments
        ? Object.values(data.comments).sort((a, b) => a.timestamp - b.timestamp)
        : [];
      // denormalized data here makes deleting easier
      data.commentList.forEach(comment => {
        comment.episodeKey = data.episodeKey;
        comment.elementId = data.elementId;
      });
      data.withMessages = true; // drives thread marker UI; we can assume true if included here

      objResult[data.elementId] = data;
      result.push(data);
    });
    this.list = result;
    this.obj = objResult;
    this.stateVersion++;
    return result;
  }

  load(options = {}): void {
    runInAction(() => (this.status = IN_PROGRESS));
    this.close();

    const handleResult = (result: QuerySnapshot) => {
      console.log(`um-handleResult`);
      runInAction(() => {
        this.list = this.snapshotToData(result);
        this.status = COMPLETE;
        console.log(`UserManager - status = COMPLETE`);
      });
    };

    const queryRef = this.loadReference(options);
    if (this.listenMode) {
      const unsubscribeFn = queryRef.onSnapshot(handleResult);
      this.disposers.push(unsubscribeFn);
    } else {
      queryRef.get().then(handleResult);
    }
  }

  close() {
    for (const disposer of this.disposers) {
      disposer();
    }
    this.disposers = [];
  }

  getStateVersion() {
    return this.status + '_' + this.stateVersion;
  }

  // note, doesn't appear to be needed for our current integration, but might be
  // useful in the future.
  // https://slack.dev/node-slack-sdk/getting-started
  // https://slack.dev/node-slack-sdk/web-api#exchange-an-oauth-grant-for-a-token
  async getSlackToken() {
    const clientId = process.env.SLACK_CLIENT_ID;
    const clientSecret = process.env.SLACK_CLIENT_SECRET;
    const code = '...';

    const result = await new WebClient().oauth.v2.access({
      client_id: clientId,
      client_secret: clientSecret,
      code,
    });
    console.log(`result token: ${result}`);
  }

  async helloSlack() {
    const web = new WebClient(deploymentConfig.slackApiToken);
    const channel = deploymentConfig.slackConversationsChannel;
    await web.chat.postMessage({
      channel: channel,
      text: 'how now brown cow',
    });
  }

  // private
  async notifySlack({ threadId, topic, linkPath, author, content }) {
    const userManager = theUserManager();
    const slackifiedContent = userManager.slackifyMentions(content);
    console.log(`slackifiedContent: ${slackifiedContent}`);

    const baseUrl = deploymentConfig.scriptEditorUrl;
    // note, doesn't seem like a leading '#' matters
    const channel = deploymentConfig.slackConversationsChannel;
    console.log(`slack channel: ${channel}, slack token: ${deploymentConfig.slackApiToken}`);
    const web = new WebClient(deploymentConfig.slackApiToken);

    var message = `_${author}_: ${slackifiedContent}`;
    if (!threadId) {
      // todo: refactor to use link (or conversation instance method)
      const link = `<${baseUrl}${linkPath}|${topic}>`;
      message = `${link}\n${message}`;
    }
    const result = await web.chat.postMessage({
      text: message,
      channel,
      thread_ts: threadId,
      unfurl_links: false,
    });
    console.log(`slack result: ${JSON.stringify(result)}`);
    const resultThreadId = result.ts;
    return resultThreadId;
  }
}
