import { Alert, AlertMessages } from '../../../alert-messages';
import {
  Element,
  ElementId,
  isEmpty,
  notNil,
  SentenceDTO,
  SpanAnchors,
  SpanExclusiveAnchors,
  WordId,
} from '../../../basic-types';
import {
  getSentencesWordStrings,
  getSentenceWordIdRange,
  getSentenceWordStrings,
  getTranscriptWordsFromString,
  strongNormalizeWordArray,
} from '../../../content-funcs';
import { EKinds } from '../../../elements/element-kinds';
import { ElementList } from '../../../elements/element-list';
import { MutationActions } from '../../db/mutation-actions';
import { doDiffAndPatch } from '../../ids/positional-diff-patch';
import {
  getIndex,
  IndexMapping,
  isDeletedId,
  makeIndexToIdMapping,
} from '../../ids/positional-ids';

// [<ImportMember("../../ids/positional-diff-patch.js")>]

function sanityCheckVerbatimUpdate(
  oldIndexMap: IndexMapping,
  newIndexMap: IndexMapping,
  oldWords: string[],
  newWords: string[],
  oldSentences: Element[]
) {
  let mismatchRegionsCount = 0;
  let mismatchCount = 0;
  let maxMismatchCount = 0;

  // all word text is not empty string or undefined
  const allAreWords = (words: string[]) => {
    for (const word of words) {
      if (isEmpty(word.trim())) {
        // TODO check implementation
        return false;
      }
    }
    return true;
  };

  if (!allAreWords(oldWords) || !allAreWords(newWords)) {
    return false;
  }

  for (const sentence of oldSentences) {
    let anchors = <SpanExclusiveAnchors>sentence.anchors;
    const sentenceStartWordId = anchors.startWordId;
    const sentenceEndWordId = anchors.endWordIdExclusive;
    const oldSentenceStartIndex = getIndex(oldIndexMap, sentenceStartWordId);
    const newSentenceStartIndex = getIndex(newIndexMap, sentenceStartWordId);
    const oldSentenceEndIndex = getIndex(oldIndexMap, sentenceEndWordId) - 1;
    const newSentenceEndIndex = getIndex(newIndexMap, sentenceEndWordId) - 1;
    const oldSentenceWords = [];
    for (let i = oldSentenceStartIndex; i <= oldSentenceEndIndex; i++) {
      oldSentenceWords.push(oldWords[i]);
    }
    const newSentenceWords = [];
    for (let i = newSentenceStartIndex; i <= newSentenceEndIndex; i++) {
      newSentenceWords.push(newWords[i]);
    }
    if (!allAreWords(oldSentenceWords) || !allAreWords(newSentenceWords)) {
      return false;
    }
    let mismatch = false;
    if (oldSentenceWords.length !== newSentenceWords.length) {
      mismatch = true;
    }
    if (!mismatch) {
      mismatch = JSON.stringify(oldSentenceWords) !== JSON.stringify(newSentenceWords);
    }
    if (mismatch) {
      if (mismatchCount === 0) {
        mismatchRegionsCount = mismatchRegionsCount + 1;
      }
      mismatchCount++;
      if (mismatchCount > maxMismatchCount) {
        maxMismatchCount = mismatchCount;
      }
    } else {
      mismatchCount = 0;
    }
  }
  return mismatchRegionsCount <= 1 && maxMismatchCount <= 2;
}

function computeVerbatimUpdate(
  sentenceId: ElementId,
  newSentenceVerbatim: string,
  currentSentences: ElementList,
  currentIndexMap: IndexMapping
) {
  const sentence = currentSentences.getElement(sentenceId);
  const sentenceAnchors = <SpanExclusiveAnchors>sentence.anchors;
  const currentDomainWords = currentSentences.words;
  const newSentenceWords = getTranscriptWordsFromString(newSentenceVerbatim);
  const currentSentenceWords = getSentenceWordStrings(sentence, currentDomainWords);
  const normalizedNewWords = strongNormalizeWordArray(newSentenceWords);
  const normalizedCurrentWords = strongNormalizeWordArray(currentSentenceWords);
  const currentStartIndex = currentSentences.wordAddress(sentenceId);
  const patchResult = doDiffAndPatch(
    currentStartIndex,
    normalizedCurrentWords,
    currentIndexMap,
    normalizedNewWords
  );
  const newIndexMap: IndexMapping = patchResult.newIndexMapping;
  const currentStartWordId = sentenceAnchors.startWordId;
  const newStartIndex = getIndex(newIndexMap, currentStartWordId);
  const newWords: string[] = [];
  for (const s of currentSentences.elements) {
    if (s.id === sentenceId) {
      newWords.push(...newSentenceWords);
    } else {
      newWords.push(...getSentenceWordStrings(s, currentDomainWords)); // TODO refactor functions?
    }
  }
  let adjustedStartWordId = null;
  if (currentStartIndex !== newStartIndex) {
    const indexToId = makeIndexToIdMapping(newIndexMap);
    adjustedStartWordId = indexToId[currentStartIndex];
  }

  return {
    newIndexMapping: newIndexMap,
    newWords,
    adjustedStartWordId,
  };
}

// [<AbstractClass>]
export abstract class VerbatimActions {
  getCurrentSentences() {
    return this.content.getKindSubList(EKinds.SENTENCE);
  }
  getCurrentWordGroups() {
    return this.content.getKindSubList(EKinds.WORD_GROUP);
  }

  computeAndSanityCheckVerbatimUpdate(
    sentenceId: ElementId,
    content: string,
    currentSentences: ElementList
  ) {
    const currentIndexMap = this.content.words.wordsIndexMapping;
    const verbatimUpdate = computeVerbatimUpdate(
      sentenceId,
      content,
      currentSentences,
      currentIndexMap
    );
    const newIndexMap = verbatimUpdate.newIndexMapping;
    const indexToId = makeIndexToIdMapping(newIndexMap);
    const newWords = verbatimUpdate.newWords;
    const currentWords = getSentencesWordStrings(currentSentences);
    const wordElements: Element[] = [];
    for (const [index, word] of newWords.entries()) {
      // TODO use strong typing for word elements?
      (<any[]>wordElements).push({ kind: EKinds.WORD, id: indexToId[index], text: word });
    }

    if (
      sanityCheckVerbatimUpdate(
        currentIndexMap,
        newIndexMap,
        currentWords,
        newWords,
        currentSentences.elements
      )
    ) {
      return {
        wordElements,
        newIndexMap,
        adjustedStartWordId: verbatimUpdate.adjustedStartWordId,
      };
    } else {
      return null;
    }
  }

  computeSentenceShiftData(id: ElementId, newStartWordId: WordId) {
    const currentSentences = this.getCurrentSentences();
    const sentence = currentSentences.getElement(id);
    const sentenceAnchors = <SpanExclusiveAnchors>sentence.anchors;
    const prevSentence = currentSentences.getElement(currentSentences.prevId(id));
    const sentenceMutationData = {
      id: sentence.id,
      anchors: {
        startWordId: newStartWordId,
        endWordIdExclusive: sentenceAnchors.endWordIdExclusive,
      },
    };
    const prevSentenceMutationData = {
      id: prevSentence.id,
      anchors: {
        startWordId: prevSentence.anchors['startWordId'],
        endWordIdExclusive: newStartWordId,
      },
    };
    return { sentence: sentenceMutationData, prevSentence: prevSentenceMutationData };
  }

  updateSentence(id: ElementId, content: string, allowZeroLength: boolean = false) {
    const newSentenceWords = getTranscriptWordsFromString(content);
    if (newSentenceWords.length === 0 && !allowZeroLength) {
      this.alertMessages.add({
        ...Alert,
        text: 'cannot edit sentence to contain no words, delete instead',
      });
      return;
    }

    const normalizedNewWords = strongNormalizeWordArray(newSentenceWords);
    const emptyWord = normalizedNewWords.find((text: string) => text.length === 0);
    if (notNil(emptyWord)) {
      this.alertMessages.add({ ...Alert, text: 'edit contains disallowed standalone punctuation' });
      return;
    }

    const sentence = this.content.getElement(id);
    const sentenceWordRange = getSentenceWordIdRange(sentence, this.content.words);
    const currentWordGroups =
      this.getCurrentWordGroups().getElementsIntersectWordIdRange(sentenceWordRange);
    let testCurrentWordGroups = true;
    if (currentWordGroups) {
      for (const group of currentWordGroups) {
        if (group.wordAddress > group.endWordAddress) {
          testCurrentWordGroups = false;
        }
      }
    } else {
      testCurrentWordGroups = false;
    }

    const verbatimUpdate = this.computeAndSanityCheckVerbatimUpdate(
      id,
      content,
      this.getCurrentSentences()
    );

    if (testCurrentWordGroups) {
      const newMap = verbatimUpdate.newIndexMap;
      for (const group of currentWordGroups) {
        const groupAnchors = <SpanAnchors>group.anchors;
        const groupStart = getIndex(newMap, groupAnchors.startWordId);
        const groupEnd = getIndex(newMap, groupAnchors.endWordId);
        if (groupStart >= groupEnd && isDeletedId(newMap, groupAnchors.endWordId)) {
          this.alertMessages.add({
            ...Alert,
            text: 'edit not allowed because completely destroys word region of existing word group',
          });
          return;
        }
      }
    }

    if (verbatimUpdate) {
      // TODO need to pass sentence updates not just Null
      let sentenceUpdates = null;
      if (verbatimUpdate.adjustedStartWordId) {
        const shiftAdjustments = this.computeSentenceShiftData(
          id,
          verbatimUpdate.adjustedStartWordId
        );
        sentenceUpdates = [shiftAdjustments.sentence, shiftAdjustments.prevSentence];
      } else {
        sentenceUpdates = [{ id: id, anchors: sentence.anchors }];
      }
      this.mutationActions.updateVerbatim(
        verbatimUpdate.wordElements,
        verbatimUpdate.newIndexMap,
        sentenceUpdates
      );
    } else {
      this.alertMessages.add({ ...Alert, text: 'error with verbatim sanity check' });
    }
  }

  createSentence(text: string, adjacent: ElementId, above: boolean) {
    // TODO
    // need to compute and sanity check verbatim update
    const currentSentences = this.getCurrentSentences();
    const adjacentSentence = currentSentences.getElement(adjacent);
    const adjacentSentenceAnchors = <SpanExclusiveAnchors>adjacentSentence.anchors;
    const newSentences: Element[] = [];
    let dummyAnchors = null;
    if (above) {
      const startWordId = adjacentSentenceAnchors.startWordId;
      dummyAnchors = { startWordId: startWordId, endWordIdExclusive: startWordId };
    } else {
      const endWordId = adjacentSentenceAnchors.endWordIdExclusive;
      dummyAnchors = { startWordId: endWordId, endWordIdExclusive: endWordId };
    }
    const dummySentenceId = 'DUMMY';
    const dummySentence: Element = <any>{
      kind: EKinds.SENTENCE,
      id: dummySentenceId,
      anchors: dummyAnchors,
    };

    for (const sentence of currentSentences.elements) {
      if (sentence === adjacentSentence) {
        if (above) {
          newSentences.push(dummySentence);
          newSentences.push(sentence);
        } else {
          newSentences.push(sentence);
          newSentences.push(dummySentence);
        }
      }
    }

    const existingWords = currentSentences.words;
    const newSentencesList = new ElementList(
      newSentences,
      '',
      null,
      existingWords,
      existingWords.words.wordsIndexMapping,
      null,
      null
    );
    const verbatimUpdate = this.computeAndSanityCheckVerbatimUpdate(
      dummySentenceId,
      text,
      newSentencesList
    );
    if (verbatimUpdate) {
      let sentenceUpdates = null;
      if (verbatimUpdate.adjustedStartWordId) {
        const shiftAdjustments = this.computeSentenceShiftData(
          dummySentenceId,
          verbatimUpdate.adjustedStartWordId
        );
        const newSentenceData: SentenceDTO = shiftAdjustments.sentence;
        newSentenceData.id = null;
        sentenceUpdates = [newSentenceData, shiftAdjustments.prevSentence];
        this.mutationActions.updateVerbatim(
          verbatimUpdate.wordElements,
          verbatimUpdate.newIndexMap,
          sentenceUpdates
        );
      } else {
        this.alertMessages.add({ ...Alert, text: "can't add sentence with no content" });
      }
    } else {
      this.alertMessages.add({ ...Alert, text: 'error with verbatim sanity check' });
    }
  }

  // TODO enum for direction instead of above bool?
  splitSentence(id: ElementId, wordId: ElementId, above: boolean) {
    const wordGroups = this.getCurrentWordGroups();
    const wordAddress = this.content.words.getIndex(wordId);
    const boundaryWordGroup = wordGroups.getElementContainingWordAddress(wordAddress);
    if (boundaryWordGroup) {
      if (boundaryWordGroup.wordAddress !== wordAddress) {
        this.alertMessages.add({ ...Alert, text: 'cannot split sentence in middle of word group' });
        return;
      }
    }
    const sentence = this.getCurrentSentences().getElement(id);
    if (sentence.anchors['startWordId'] === wordId) {
      this.alertMessages.add({
        ...Alert,
        text: 'cannot split sentence in way that creates empty sentence',
      });
      return;
    }
    this.mutationActions.splitSentence(
      id,
      wordId,
      above,
      this.content.getKindSubList(EKinds.SENTENCE)
    );
  }

  removeSentence(id: ElementId) {
    const sentence = this.getCurrentSentences().getElement(id);
    const sentenceWordRange = getSentenceWordIdRange(sentence, this.content.words);
    const wordGroups = this.getCurrentWordGroups();
    if (wordGroups.hasElementsIntersectWordIdRange(sentenceWordRange)) {
      this.alertMessages.add({
        ...Alert,
        text: "error can't delete sentence that contains word groups, delete word groups first",
      });
    } else {
      // TODO remove all words in sentence by passing empty string to update sentence with zero length override??
      this.updateSentence(id, '', true);
      this.mutationActions.removeSentence(id); // TODO race condition with above async op, make internal JS.Promise returning implementation of updateSentence?
    }
  }

  abstract get mutationActions(): MutationActions;
  abstract get content(): ElementList;
  abstract get alertMessages(): AlertMessages;
}
