import { MDBModal, MDBModalBody } from 'mdbreact';
import React from 'react';
import Keyboardist from 'react-keyboardist';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { scriptEditorModel } from './app-root';
import { BaseFilterKinds } from './masala-lib/editorial/filters/filters-model';

class FiltersDialogState {
  constructor() {
    this.open = false;
    this.oneChoice = false;
    makeObservable(this, {
      open: observable,
    });
  }
}

const dialogState = new FiltersDialogState();

export function openFiltersDialog(oneChoice) {
  dialogState.open = true;
  dialogState.oneChoice = oneChoice;
  scriptEditorModel.setKbDeactivated(true);
}

export const FiltersDialog = observer(() => {
  const toggleModal = () => {
    dialogState.open = !dialogState.open;
    scriptEditorModel.setKbDeactivated(dialogState.open);
  };

  if (!dialogState.open) {
    return null;
  }

  const getFilterOnState = key => {
    return scriptEditorModel.filters.activeFlags.has(key);
  };

  const setFilterOnState = (key, value) => {
    scriptEditorModel.filters.setFlag(key, value);
  };

  const setAllFiltersOffExcept = exceptKey => {
    for (const key of scriptEditorModel.filters.activeFlags) {
      if (key !== exceptKey) {
        setFilterOnState(key, false);
      }
    }
  };

  const toggleFilterOnState = key => {
    if (dialogState.oneChoice) {
      setAllFiltersOffExcept(key);
    }
    scriptEditorModel.filters.setFlag(key, !getFilterOnState(key));
    if (dialogState.oneChoice) {
      toggleModal();
      setTimeout(() => scriptEditorModel.nextSearchResult(), 50);
    }
  };

  const clearChoices = () => {
    setAllFiltersOffExcept(null);
    if (dialogState.oneChoice) {
      toggleModal();
    }
  };

  return (
    <MDBModal size="lg" isOpen={dialogState.open} toggle={toggleModal}>
      <MDBModalBody>
        <Keyboardist
          bindings={{
            KeyG: () => toggleFilterOnState(BaseFilterKinds.WORD_GROUP),
            KeyV: () => toggleFilterOnState(BaseFilterKinds.VOCAB),
            KeyT: () => toggleFilterOnState(BaseFilterKinds.TRICKY),
            KeyS: () => toggleFilterOnState(BaseFilterKinds.STRUCTURAL),
            KeyU: () => toggleFilterOnState(BaseFilterKinds.UNFILLED),
            KeyW: () => toggleFilterOnState(BaseFilterKinds.WARNING),
            KeyA: () => toggleFilterOnState(BaseFilterKinds.ASSIGNED),
            KeyP: () => toggleFilterOnState(BaseFilterKinds.AREPARTICIPANT),
            KeyO: () => toggleFilterOnState(BaseFilterKinds.OPEN),
            KeyX: () => clearChoices(),
          }}
        />
        <div>
          <div className="filter-column">
            <div>
              {/* TODO factor the duplicate code in these labels to component which takes key string and ui text string? */}
              <label>
                <input
                  type="checkbox"
                  checked={getFilterOnState(BaseFilterKinds.WORD_GROUP)}
                  onChange={e => setFilterOnState(BaseFilterKinds.WORD_GROUP, e.target.checked)}
                />
                <span>
                  {' '}
                  word{' '}
                  <b>
                    <u>g</u>
                  </b>
                  roup{' '}
                </span>
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={getFilterOnState(BaseFilterKinds.SENTENCE)}
                  onChange={e => setFilterOnState(BaseFilterKinds.SENTENCE, e.target.checked)}
                />
                <span> sentence </span>
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={getFilterOnState(BaseFilterKinds.STRUCTURAL)}
                  onChange={e => setFilterOnState(BaseFilterKinds.STRUCTURAL, e.target.checked)}
                />
                <span>
                  {' '}
                  <b>
                    <u>s</u>
                  </b>
                  tructural{' '}
                </span>
              </label>
            </div>
          </div>
          <div className="filter-column">
            <div>
              <label>
                <input
                  type="checkbox"
                  checked={getFilterOnState(BaseFilterKinds.VOCAB)}
                  onChange={e => setFilterOnState(BaseFilterKinds.VOCAB, e.target.checked)}
                />
                <span>
                  {' '}
                  <b>
                    <u>v</u>
                  </b>
                  ocab{' '}
                </span>
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={getFilterOnState(BaseFilterKinds.TRICKY)}
                  onChange={e => setFilterOnState(BaseFilterKinds.TRICKY, e.target.checked)}
                />
                <span>
                  {' '}
                  <b>
                    <u>t</u>
                  </b>
                  ricky{' '}
                </span>
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={getFilterOnState(BaseFilterKinds.SIC)}
                  onChange={e => setFilterOnState(BaseFilterKinds.SIC, e.target.checked)}
                />
                <span> sic </span>
              </label>
            </div>
          </div>
          <div className="filter-column">
            <div>
              <label>
                <input
                  type="checkbox"
                  checked={getFilterOnState(BaseFilterKinds.OPEN)}
                  onChange={e => setFilterOnState(BaseFilterKinds.OPEN, e.target.checked)}
                />
                <span>
                  {' '}
                  <b>
                    <u>o</u>
                  </b>
                  pen{' '}
                </span>
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={getFilterOnState(BaseFilterKinds.AREPARTICIPANT)}
                  onChange={e => setFilterOnState(BaseFilterKinds.AREPARTICIPANT, e.target.checked)}
                />
                <span>
                  {' '}
                  <b>
                    <u>p</u>
                  </b>
                  articipant{' '}
                </span>
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={getFilterOnState(BaseFilterKinds.ASSIGNED)}
                  onChange={e => setFilterOnState(BaseFilterKinds.ASSIGNED, e.target.checked)}
                />
                <span>
                  {' '}
                  <b>
                    <u>a</u>
                  </b>
                  ssigned{' '}
                </span>
              </label>
            </div>
          </div>
          <div className="filter-column">
            <div>
              <label>
                <input
                  type="checkbox"
                  checked={getFilterOnState(BaseFilterKinds.WARNING)}
                  onChange={e => setFilterOnState(BaseFilterKinds.WARNING, e.target.checked)}
                />
                <span>
                  {' '}
                  <b>
                    <u>w</u>
                  </b>
                  arning{' '}
                </span>
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={getFilterOnState(BaseFilterKinds.UNFILLED)}
                  onChange={e => setFilterOnState(BaseFilterKinds.UNFILLED, e.target.checked)}
                />
                <span>
                  {' '}
                  <b>
                    <u>u</u>
                  </b>
                  nfilled{' '}
                </span>
              </label>
            </div>
          </div>
          <div>X = clear all choices</div>
        </div>
      </MDBModalBody>
    </MDBModal>
  );
});
