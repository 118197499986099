import { SentenceView, SentenceLineEditor } from './script-editor-view.js';

export const lineEditorConfigurations = {
  CHAPTER_TITLE: {
    prefix: '# ',
    style: 'chapter-title',
  },
  CHAPTER_SUMMARY: {
    prefix: '%% ',
    style: 'chapter-summary',
  },
  CULTURAL_NOTE: {
    prefix: '% ',
    style: 'cultural-note',
    opts: { multiline: true },
  },
  PASSAGE_HINT: {
    prefix: '## ',
    style: 'passage-hint',
  },
  SPEAKER_LABEL: {
    prefix: '@',
    style: 'speaker-label',
  },
  SENTENCE: {
    viewComponent: SentenceView,
    editorComponent: SentenceLineEditor,
    prefix: null,
    style: 'sentence',
  },
  PARAGRAPH_BREAK: {
    prefix: '###',
    style: 'paragraph-break',
  },
  METADATA_BLOCK: {
    prefix: null,
    style: 'metadata-block',
    opts: { multiline: true },
  },
  CHAPTER_COMPLETE: {
    prefix: '//! CHAPTER-COMPLETE',
    style: 'chapter-complete',
  },
  TRANSLATION: {
    prefix: null,
    style: 'translation',
  },
};
