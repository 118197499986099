// open BasicTypes
// open WordGroupActions
// open AppRoot

import { alertMessages, mutationActions, scriptEditorModel } from '../app-root';
import { WordGroupActions } from '../masala-lib/editorial/models/actions/word-group-actions';

// type WordGroupActions1() =
export class ScriptEditorWordGroupActions extends WordGroupActions {
  get mutationActions() {
    return mutationActions;
  }
  get content() {
    return scriptEditorModel.elements;
  }
  get currentWordSelection() {
    return scriptEditorModel.wordRangeSelection;
  }
  get currentFocusedElement() {
    return scriptEditorModel.focusedElement;
  }
  get alertMessages() {
    return alertMessages;
  }
}

// let WordGroupActions():IWordGroupActions = !< WordGroupActions1()
