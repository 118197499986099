import { reaction, runInAction } from 'mobx';
import { makeObservable, observable } from 'mobx';
import { ElementId, getKindFromId, numberProjectionSort } from '../../basic-types';
import { EKinds } from '../../elements/element-kinds';
import { VersionsLoader } from '../db/versions-loader';

export class Versions {
  @observable.ref sentenceVersions = {};

  @observable.ref structuralVersions = {};

  @observable.ref wordGroupVersions = {};

  @observable.ref translationVersions = {};

  disposers: (() => void)[] = [];

  loader = new VersionsLoader();

  constructor() {
    makeObservable(this);
    this.disposers.push(
      reaction(
        () => this.loader.getStateVersion(),
        () => this.versionsUpdated()
      )
    );
  }

  lazyLoadVersions(episodeKey: string) {
    setTimeout(() => this.loader.loadEpisode(episodeKey, true), 1500);
  }

  getVersions(id: ElementId) {
    const kind = getKindFromId(id);

    const filterElementVersions = (elements: Element[]) => {
      elements = elements.filter((el: Element) => el.id === id).slice();
      numberProjectionSort(elements, el => -el.timestamp);
      return elements;
    };

    if (kind === EKinds.SENTENCE) {
      return filterElementVersions(Object.values(this.sentenceVersions));
    } else if (kind === EKinds.WORD_GROUP) {
      return filterElementVersions(Object.values(this.wordGroupVersions));
    } else if (kind === EKinds.TRANSLATION) {
      return filterElementVersions(Object.values(this.translationVersions));
    } else {
      return filterElementVersions(Object.values(this.structuralVersions));
    }
  }

  versionsUpdated() {
    console.log('versions updated: ' + this.loader.getStatus());
    if (this.loader.getStatus() === 'COMPLETE') {
      console.log('COMPLETE: ' + this.loader.key);
      runInAction(() => {
        this.sentenceVersions = this.loader.docSet.sentenceVersionsDoc.versions;
        this.wordGroupVersions = this.loader.docSet.wordGroupVersionsDoc.versions;
        this.structuralVersions = this.loader.docSet.structuralVersionsDoc.versions;
        this.translationVersions = this.loader.docSet.translationVersionsDoc.versions;
      });
    }
  }
}
