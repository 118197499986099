import { computed, makeObservable } from 'mobx';
import { ElementId } from '../../basic-types';
import { EKinds } from '../../elements/element-kinds';
import { ContentRootsBase } from '../content-roots/content-roots';

export type ValidatorWarning = {
  elementId: ElementId;
  message: string;
  // }
};

export class Validations {
  contentRoots: ContentRootsBase;
  filterSuppressed: boolean = true;

  constructor(contentRoots0: ContentRootsBase) {
    this.contentRoots = contentRoots0;
    makeObservable(this);
  }

  @computed({ keepAlive: true })
  get allWarnings() {
    const structuralElements = this.contentRoots.structural0;
    const result = [];
    for (const element of structuralElements) {
      const content: string = element.content;
      if (element.kind === EKinds.PASSAGE_HINT) {
        if (content && content.length > 80) {
          const warning = {
            elementId: element.id,
            message: 'Passage hint text is over char limit',
          };
          result.push(warning);
        }
      }
    }
    return result;
  }

  @computed({ keepAlive: true })
  get activeWarnings() {
    if (!this.filterSuppressed) {
      return this.allWarnings;
    } else {
      const suppressions = this.contentRoots.warningSuppressions;
      // TODO include warning kind in warning struct and in key for suppression
      return this.allWarnings.filter(warning => !suppressions.has(warning.elementId));
    }
  }

  setFilterSuppressed(value: boolean) {
    this.filterSuppressed = value;
  }
}
