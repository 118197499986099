//
// shared layout for non-editor views to match layout style of the editor view

import React from 'react';
import { SimpleStatusBar } from './simple-status-bar';

import '../App.css';

export const MainLayout = ({ title, children }) => {
  return (
    <div style={{ height: '100%' }}>
      <div id={'main-container'}>
        <div className={'left-sidepanel'} />
        <div className={'editor-main'}>
          <SimpleStatusBar element={title} />
          <div style={{ padding: 8 }}>{children}</div>
        </div>
        <div className={'right-sidepanel'} />
      </div>
    </div>
  );
};
