import React from 'react';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';

import '../App.css';

export const DefaultRoute = observer(() => {
  // note, the AppView will take care of rendering the LoginScreen if unauthenticated
  return <Redirect to="/home" />;
});
