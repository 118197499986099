import { Alert, AlertMessages } from '../../../alert-messages';
import {
  ElementId,
  ElementKind,
  getKindFromId,
  notNull,
  SpanExclusiveAnchors,
} from '../../../basic-types';
import { elementKindAnchorDirection } from '../../../elements/anchors';
import { EKinds } from '../../../elements/element-kinds';
import { ElementList } from '../../../elements/element-list';
import { randomString } from '../../../utils';
import { MutationActions } from '../../db/mutation-actions';

// [<AbstractClass>]
export abstract class StructuralActions {
  getSentences() {
    return this.content.getKindSubList(EKinds.SENTENCE);
  }

  getAnchorWordId(kind: ElementKind, initialTargetId: ElementId) {
    let targetSentence = null;
    let direction: number = elementKindAnchorDirection[kind];
    if (getKindFromId(initialTargetId) === EKinds.SENTENCE) {
      targetSentence = this.content.getElement(initialTargetId);
    } else {
      const targetId = this.getSentences().stepId(initialTargetId, false, direction);
      targetSentence = this.content.getElement(targetId);
    }
    if (targetSentence) {
      const sentenceSpan: SpanExclusiveAnchors = targetSentence.anchors;
      return direction === 1 ? sentenceSpan.startWordId : sentenceSpan.endWordIdExclusive;
    } else {
      return null;
    }
  }

  create(kind: ElementKind, initialTargetId: ElementId): ElementId {
    const anchorWordId = this.getAnchorWordId(kind, initialTargetId);
    if (notNull(anchorWordId)) {
      // TODO is there null version 0 wordId problem?
      const newId = `${kind}:${randomString(12)}`; // TODO factor to mutation actions?
      this.mutationActions.addUpdateStructural({
        kind,
        id: newId,
        content: '',
        anchors: anchorWordId,
      });
      return newId;
    } else {
      return null;
      // TODO alert can't find sentence anchor to create structural content
    }
  }

  move(id: ElementId, initialTargetId: ElementId) {
    const element = this.content.getElement(id);
    const newAnchorWordId = this.getAnchorWordId(element.kind, initialTargetId);
    if (notNull(newAnchorWordId)) {
      // TODO is there null version 0 wordId problem?
      this.mutationActions.addUpdateStructural({
        kind: element.kind,
        id: element.id,
        content: element.content, // TODO use strong typing
        anchors: newAnchorWordId,
      });
    } else {
      this.alertMessages.add({
        ...Alert,
        text: "can't find sentence anchor to move structural content",
      });
    }
  }

  updateContent(id: ElementId, content: string) {
    this.mutationActions.updateContentStructural(id, content);
  }

  // TODO some other actions classes are contextual and operate on the focused object in the editor
  // TODO should incorporate that here to be consistent? removeFocused or something??
  remove(id: ElementId) {
    this.mutationActions.removeStructural(id);
  }

  revert(versionObject: any) {
    this.mutationActions.revertStructural(versionObject);
  }

  abstract get mutationActions(): MutationActions;
  abstract get content(): ElementList;
  abstract get alertMessages(): AlertMessages;
}
