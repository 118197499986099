import { MDBModal, MDBModalBody, MDBModalFooter, MDBBtn } from 'mdbreact';
import React from 'react';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { scriptEditorModel, versions } from './app-root';
import { getElementVersionDescriptiveContent } from './masala-lib/content-funcs';
import { VersionItem } from './masala-lib/editorial/ui/timeline-components.js';

class VersionsDialogState {
  constructor() {
    this.open = false;
    this.selectedTS = null;
    makeObservable(this, {
      open: observable,
      selectedTS: observable.ref,
    });
  }
}

const dialogState = new VersionsDialogState();

export function openVersionsDialog() {
  dialogState.open = true;
  scriptEditorModel.setKbDeactivated(true);
}

export const VersionsDialog = observer(() => {
  const toggleModal = () => {
    dialogState.open = !dialogState.open;
    scriptEditorModel.setKbDeactivated(dialogState.open);
  };

  if (!dialogState.open) {
    return null;
  }

  const elementId = scriptEditorModel.focusedElementId;
  const elementVersions = elementId ? versions.getVersions(elementId) : [];
  const versionItems = elementVersions.map(v => {
    return { ...v, content: getElementVersionDescriptiveContent(v, scriptEditorModel.words) };
  });

  const revertTo = ts => {
    if (!ts) {
      return;
    }
    const version = elementVersions.find(version => version.timestamp === ts);
    scriptEditorModel.revertToVersion(version);
  };

  const setSelectedTS = ts => {
    dialogState.selectedTS = ts;
  };

  return (
    <MDBModal isOpen={dialogState.open} toggle={toggleModal}>
      <MDBModalBody>
        <div className="comment-thread">
          {versionItems.map(item => (
            <VersionItem
              key={item.timestamp}
              onClick={() => setSelectedTS(item.timestamp)}
              className={item.timestamp === dialogState.selectedTS ? 'selected' : ''}
              item={item}
            />
          ))}
        </div>
      </MDBModalBody>
      <MDBModalFooter className="justify-content-center">
        <>
          <MDBBtn
            color="info"
            onClick={() => {
              revertTo(dialogState.selectedTS);
              toggleModal();
            }}
          >
            Revert To
          </MDBBtn>
        </>
      </MDBModalFooter>
    </MDBModal>
  );
});
