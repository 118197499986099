// open BasicTypes
// open Fable.Core
// open Fable.Core.JsInterop
// open Fable.React
// open SweetAlert
// open BasicTypes
// open ElementList
// open ElementTypes
// open Singletons
// open Keyboardist
// open AppRoot
// open Mobx
// open AlertMessages
// open StyleLayers
// open Browser.Types
// open AdhocWordRange
// open DomScroll
// open JSBoilerplate
// open ScriptEditorModel
// open Browser
// open System.Text.RegularExpressions

import { computed, makeObservable, observable, reaction } from 'mobx';
import React from 'react';
import { alertMessages, keyboardModes, scriptEditorModel } from './app-root';
import { Alert } from './masala-lib/alert-messages';
import { Element, getKindFromId, domIdToElementId, isEmpty } from './masala-lib/basic-types';
import { Location, getVisibility, scrollIfNotVisible } from './masala-lib/editorial/ui/dom-scroll';
import { EKinds } from './masala-lib/elements/element-kinds';
import { swalPromptYN } from './masala-lib/sweetalert-yn';
import {
  CHOICE_MODE,
  DEACTIVATED,
  EDITING,
  LOCKED,
  NORMAL,
  ScriptEditorModel,
} from './script-editor-model';
import { test1 } from './test';
import Keyboardist from 'keyboardist';
import { openFiltersDialog } from './filters-dialog';
import { openVersionsDialog } from './versions-dialog';
import { openWordGroupInspectorDialog } from './word-group-inspector/word-group-inspector-dialog';
import { ElementList, SimpleElementList } from './masala-lib/elements/element-list';
import { makeAdhocWordRange } from './masala-lib/elements/ad-hoc-word-range';
import { observer } from 'mobx-react';
import {
  StyleLayer,
  StyleLayersRenderer,
} from './masala-lib/editorial/ui/style-painting/style-layers';

// open TimestampsOutput
// open ChaatCRUD
// open Lib

// type IScriptEditor =

// let openWordGroupInspectorDialog():unit = import "openWordGroupInspectorDialog" "./word-group-inspector/word-group-inspector-dialog.js"
// let openFiltersDialog(oneChoice:bool):unit = import "openFiltersDialog" "./filters-dialog.js"

// [<ImportMember("./versions-dialog.js")>]
// let openVersionsDialog():unit = jsNative

// [<ImportMember("./test.js")>]
// let test1():unit = jsNative

// let inline renderScriptEditorView(editor:IScriptEditor):ReactElement = import "renderView" "./script-editor-view.js"
import { renderView as renderScriptEditorView } from './script-editor-view.js';

// let createChoice(text:string) =
function createChoice(text: string) {
  const highlight = (str: string) => str.replace(/@(.)/, '<strong><u>$1</u></strong>');
  return `<div>${highlight(text)}</div>`;
}

const VERBATIM_OPS = 'VERBATIM_OPS';
const WORD_GROUP_CREATE = 'WORD_GROUP_CREATE';
const STRUCTURAL_CREATE = 'STRUCTURAL_CREATE';
const METADATA_BLOCK_OPS = 'METADATA_BLOCK_OPS';

// type ScriptEditor0(initialProps) as s0 =
@observer
export class ScriptEditor extends React.Component<any> {
  model: ScriptEditorModel = scriptEditorModel;
  activeLineEditor: any = null;
  lastClickTime = 0.0;
  currentChoiceMode: string = null;
  disposers: (() => void)[] = [];

  @observable.ref quantizedScrollPos = 0;

  constructor(props) {
    super(props);
    makeObservable(this);
    this.initializeInputHandlers();
    this.model.setGetLineEditBuffer(() => this.activeLineEditor.getContentFromEditor());
    this.disposers.push(
      reaction(
        () => this.styleLayers,
        () => this.renderStyleLayers()
      )
    );
    this.disposers.push(
      reaction(
        () => this.model.mode,
        () => this.adjustInputHandlingForMode()
      )
    );
    this.disposers.push(
      reaction(
        () => this.model.focusedLineId,
        () => scrollIfNotVisible(this.model.focusedLineId, 'nearest', 'editorId')
      )
    );
    this.disposers.push(
      reaction(
        () => this.quantizedScrollPos,
        () => this.adjustCurrentChapterForScrollPos()
      )
    );
  }

  saveLineEdit() {}

  adjustInputHandlingForMode() {
    if (this.model.mode === DEACTIVATED) {
      keyboardModes.setKbDeactivated(true);
    } else {
      keyboardModes.setKbDeactivated(false);
    }

    if (this.model.mode === CHOICE_MODE) {
      keyboardModes.setMode(this.currentChoiceMode);
    } else if (this.model.mode !== DEACTIVATED) {
      keyboardModes.setMode(this.model.mode);
    }
  }

  handleKbFocusChange() {}

  exitChoiceModalInputHandling() {
    this.model.setChoiceModalMode(null);
  }

  handleKBModalChoiceMade() {
    this.exitChoiceModalInputHandling();
  }

  enterWordGroupCreateChoiceMode() {
    this.currentChoiceMode = WORD_GROUP_CREATE;
    let html = '';
    const addChoice = choiceText => (html += createChoice(choiceText));
    addChoice('@Vocab');
    addChoice('@Tricky');
    addChoice('@Sic');
    this.model.setChoiceModalMode(html);
  }

  enterStructuralCreateChoiceMode() {
    this.currentChoiceMode = STRUCTURAL_CREATE;
    let html = '';
    const addChoice = choiceText => (html += createChoice(choiceText));
    addChoice('@Chapter');
    addChoice('@Passage');
    addChoice('@Speaker Label');
    addChoice('Paragraph @Break');
    addChoice('Chapter @Note');
    addChoice('Chapter Complet@e');
    this.model.setChoiceModalMode(html);
  }

  enterVerbatimOpsChoiceMode() {
    this.currentChoiceMode = VERBATIM_OPS;
    let html = '';
    const addChoice = choiceText => (html += createChoice(choiceText));
    addChoice('Split and create new sentence @above');
    addChoice('Split and create new sentence @below');
    this.model.setChoiceModalMode(html);
  }

  enterMetadataBlockOpsChoiceMode() {
    this.currentChoiceMode = METADATA_BLOCK_OPS;
    let html = '';
    const addChoice = choiceText => (html += createChoice(choiceText));
    addChoice('Add metadata @URL block');
    addChoice('@Copy metadata blocks to clipboard');
    this.model.setChoiceModalMode(html);
  }

  handleScroll(event: Event) {
    this.quantizedScrollPos = Number(event.target['scrollTop']) % 20;
  }

  adjustCurrentChapterForScrollPos() {
    let chapter = null;
    const chapters = this.model.elements.getKindSubList(EKinds.CHAPTER_TITLE);
    for (const element of chapters.elements) {
      const placement = getVisibility(element.id, 'editorId');
      if (placement === Location.BelowScreen) {
        break;
      }
      chapter = element;
    }
    if (chapter && chapter.content) {
      this.model.currentChapterTitle = chapter.content;
    } else {
      this.model.currentChapterTitle = '';
    }
  }

  handleWordClick(event: MouseEvent, id) {
    if (event.getModifierState('Shift')) {
      this.model.wordRangeSelectTo(id);
    } else {
      this.model.setCurrentWordId(id);
    }
  }

  handleLineDoubleClick(event: MouseEvent, domId) {
    // TODO need to use domId or assume that prior click event already set focused line?
    this.model.editFocusedLine();
  }

  handleLineClick(event: MouseEvent, domId) {
    const currentTime = Date.now();
    if (currentTime < this.lastClickTime + 500.0) {
      this.lastClickTime = 0.0;
      this.handleLineDoubleClick(event, domId);
    } else {
      this.lastClickTime = currentTime;
    }
    const id = domIdToElementId(null, domId);
    const kind = getKindFromId(id);
    if (kind === EKinds.WORD) {
      this.handleWordClick(event, id);
    } else {
      this.model.setFocusedLineId(event.currentTarget['id']);
    }
  }

  getLineStylesString(id) {
    // TODO no longer needed?
    return 'styles';
  }

  setActiveLineEditor(lineEditor, isActive: boolean) {
    if (isActive) {
      this.activeLineEditor = lineEditor;
    } else if (this.activeLineEditor === lineEditor) {
      this.activeLineEditor = null;
    }
  }

  toggleCollapseExpand() {
    this.model.toggleCollapseExpand();
    setTimeout(() => scrollIfNotVisible(this.model.focusedLineId, 'center', 'editorId'), 100);
  }

  handleEnterInNormalMode() {
    if (this.model.collapse) {
      this.toggleCollapseExpand();
    } else {
      const focusedLine = this.model.focusedLineElement;
      if (focusedLine && focusedLine.kind === EKinds.SENTENCE) {
        alertMessages.add({ ...Alert, text: 'need to use Ctrl or Cmd Enter to edit verbatim' });
      } else {
        this.model.editFocusedLine();
      }
    }
  }

  handleEnterToEditSentence() {
    this.model.editFocusedLine();
  }

  abortLineEdit() {
    if (this.activeLineEditor) {
      this.activeLineEditor.revert();
      this.model.abortLineEdit();
    }
  }

  handleEnterInEdit() {
    if (this.activeLineEditor.handleEnter) {
      const handled: boolean = this.activeLineEditor.handleEnter(null);
      if (!handled) {
        this.model.saveLineEdit();
      }
    } else {
      this.model.saveLineEdit();
    }
  }

  async removeFocused() {
    let focused = this.model.focusedElement;
    if (focused && focused.kind === EKinds.SENTENCE) {
      const sentenceNum = this.model.sentences.getIndex(focused.id) + 1;
      const gohead = await swalPromptYN({
        title: 'Delete Sentence?',
        html: `Do you really want to delete sentence number ${sentenceNum}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete Sentence',
      });
      if (gohead) {
        this.model.removeFocused();
      }
    } else {
      this.model.removeFocused();
    }
  }

  // TODO move these to model?
  goToFirst() {
    const firstElementId = this.model.lineElements.elements[0].id;
    this.model.setFocusedLineId(firstElementId);
  }

  goToLast() {
    const elements = this.model.lineElements.elements;
    const lastElementId = elements[elements.length - 1].id;
    this.model.setFocusedLineId(lastElementId);
  }

  testingHook() {
    test1();
  }

  initializeInputHandlers() {
    const getKeyboardist = () => {
      const kb = Keyboardist();
      if (kb) {
        return kb;
      } else {
        throw Error('no keyboardist');
      }
    };

    let handler = getKeyboardist();
    handler.subscribe('Escape', () => this.model.cancelSelections());
    handler.subscribe('KeyJ', () => this.model.cursorLineDown());
    handler.subscribe('KeyK', () => this.model.cursorLineUp());
    handler.subscribe('Right', () => this.model.nextSearchResult());
    handler.subscribe('Left', () => this.model.prevSearchResult());
    handler.subscribe('KeyW', () => this.enterWordGroupCreateChoiceMode());
    handler.subscribe('KeyS', () => this.enterStructuralCreateChoiceMode());
    handler.subscribe('Shift+KeyM', () => this.enterMetadataBlockOpsChoiceMode());
    handler.subscribe('KeyV', () => this.enterVerbatimOpsChoiceMode());
    handler.subscribe('KeyI', () => this.model.setFocusedWarningSuppression(true));
    handler.subscribe('Shift+KeyI', () => this.model.setFocusedWarningSuppression(false));
    handler.subscribe('KeyX', () => this.toggleCollapseExpand());
    handler.subscribe('KeyM', () => this.model.markFocusedLine());
    handler.subscribe('Ctrl+KeyD', () => this.removeFocused());
    handler.subscribe('Ctrl+KeyM', () => this.model.moveMarked());
    handler.subscribe('KeyT', () => this.model.createOrEditTranslationAtFocusedLine());
    handler.subscribe('Enter', () => this.handleEnterInNormalMode());
    handler.subscribe('Meta+Enter', () => this.handleEnterToEditSentence());
    handler.subscribe('Ctrl+Enter', () => this.handleEnterToEditSentence());
    handler.subscribe('KeyF', () => openFiltersDialog(true));
    handler.subscribe('Shift+KeyF', () => openFiltersDialog(false));
    handler.subscribe('KeyH', () => openVersionsDialog());
    handler.subscribe('KeyU', () => this.model.copySharableElementLinkToClipboard());
    handler.subscribe('Digit0', () => this.goToFirst());
    handler.subscribe('Shift+Digit0', () => this.goToLast());
    handler.subscribe('Digit1', () => openWordGroupInspectorDialog());
    handler.subscribe('Digit2', () => this.testingHook());
    keyboardModes.addMode(NORMAL, handler);

    // TODO factor this common initialization instead of duplicating?
    handler = getKeyboardist();
    handler.subscribe('Escape', () => this.model.cancelSelections());
    handler.subscribe('KeyJ', () => this.model.cursorLineDown());
    handler.subscribe('KeyK', () => this.model.cursorLineUp());
    handler.subscribe('Right', () => this.model.nextSearchResult());
    handler.subscribe('Left', () => this.model.prevSearchResult());
    handler.subscribe('KeyI', () => this.model.setFocusedWarningSuppression(true));
    handler.subscribe('Shift+KeyI', () => this.model.setFocusedWarningSuppression(false));
    handler.subscribe('KeyX', () => this.toggleCollapseExpand());
    handler.subscribe('KeyF', () => openFiltersDialog(true));
    handler.subscribe('Shift+KeyF', () => openFiltersDialog(false));
    handler.subscribe('KeyH', () => openVersionsDialog());
    handler.subscribe('KeyU', () => this.model.copySharableElementLinkToClipboard());
    handler.subscribe('Digit0', () => this.goToFirst());
    handler.subscribe('Shift+Digit0', () => this.goToLast());
    handler.subscribe('Digit1', () => openWordGroupInspectorDialog());
    handler.stopListening();
    keyboardModes.addMode(LOCKED, handler);

    handler = getKeyboardist();
    handler.subscribe('Ctrl+KeyS', () => this.model.saveLineEdit());
    handler.subscribe('Escape', () => this.abortLineEdit());
    handler.subscribe('Enter', () => this.handleEnterInEdit());
    handler.stopListening();
    keyboardModes.addMode(EDITING, handler);

    const defineKBChoice = (key: string, cb: () => void) =>
      handler.subscribe(key, () => {
        this.handleKBModalChoiceMade();
        cb();
      });

    handler = getKeyboardist();
    defineKBChoice('KeyV', () => this.model.createWordGroupWithWordSelection(EKinds.VOCAB));
    defineKBChoice('KeyT', () => this.model.createWordGroupWithWordSelection(EKinds.TRICKY));
    defineKBChoice('KeyS', () => this.model.createWordGroupWithWordSelection(EKinds.SIC));
    handler.stopListening();
    // TODO use constants for these
    keyboardModes.addMode(WORD_GROUP_CREATE, handler);

    handler = getKeyboardist();
    defineKBChoice('KeyC', () => this.model.createStucturalAtFocusedLine(EKinds.CHAPTER_TITLE));
    defineKBChoice('KeyP', () => this.model.createStucturalAtFocusedLine(EKinds.PASSAGE_HINT));
    defineKBChoice('KeyS', () => this.model.createStucturalAtFocusedLine(EKinds.SPEAKER_LABEL));
    defineKBChoice('KeyB', () => this.model.createStucturalAtFocusedLine(EKinds.PARAGRAPH_BREAK));
    defineKBChoice('KeyN', () => this.model.createStucturalAtFocusedLine(EKinds.CULTURAL_NOTE));
    defineKBChoice('KeyE', () => this.model.createStucturalAtFocusedLine(EKinds.CHAPTER_COMPLETE));
    handler.stopListening();
    keyboardModes.addMode(STRUCTURAL_CREATE, handler);

    handler = getKeyboardist();
    defineKBChoice('KeyU', () => this.model.createMetadataUrlBlock());
    defineKBChoice('KeyC', () => this.model.copyMetadataToClipboard());
    handler.stopListening();
    keyboardModes.addMode(METADATA_BLOCK_OPS, handler);

    handler = getKeyboardist();
    // TODO param is bool in JS but defined as int so sloppy
    defineKBChoice('KeyA', () => this.model.splitSentence(1));
    defineKBChoice('KeyB', () => this.model.splitSentence(0));
    handler.stopListening();
    keyboardModes.addMode(VERBATIM_OPS, handler);

    this.disposers.push(() => keyboardModes.stopListening());
  }

  get focusedLineStyleLayer() {
    const els = this.model.focusedLineElement ? [this.model.focusedLineElement] : [];
    return {
      styles: ['focused-line'],
      domScope: 'LINE',
      supportWordRanges: false,
      elements: SimpleElementList(els),
      stylesString: null,
    };
  }

  get markedLineStyleLayer() {
    let els = this.model.markedLineElement ? [this.model.markedLineElement] : [];
    return {
      styles: ['marked-line'],
      domScope: 'LINE',
      supportWordRanges: false,
      elements: SimpleElementList(els),
      stylesString: null,
    };
  }

  get wordSelectionStyleLayer() {
    const els = this.model.wordRangeSelection
      ? [makeAdhocWordRange(this.model.wordRangeSelection, this.model.words)]
      : [];
    const elements = new ElementList(els, null, null, this.model.elements.words, null, null, null);
    return {
      styles: ['selected'],
      domScope: null,
      supportWordRanges: true,
      elements: elements,
      stylesString: null,
    };
  }

  // TODO
  get hasActiveThreadStyleLayer() {
    const els: Element[] = this.model.elements.elements.filter(
      el => el.kind !== EKinds.WORD_GROUP && el['thread'].withMessages && !el['thread'].resolved
    );
    return {
      styles: ['has-discussion'],
      domScope: 'LINE',
      supportWordRanges: false,
      elements: SimpleElementList(els),
      stylesString: null,
    };
  }

  get unfilledStyleLayer() {
    const els: Element[] = this.model.elements.elements.filter(
      el => el.subKind === EKinds.VOCAB && isEmpty(el.content['note'])
    );
    return {
      styles: ['unfilled'],
      domScope: null,
      supportWordRanges: false,
      elements: SimpleElementList(els),
      stylesString: null,
    };
  }

  get hasValidatorWarningStyleLayer() {
    return {
      styles: ['has-validation-warning'],
      domScope: 'LINE',
      supportWordRanges: false,
      elements: this.model.validationWarnings,
      stylesString: null,
    };
  }

  get searchFilterStyleLayer() {
    return {
      styles: ['search-match'],
      domScope: null,
      supportWordRanges: false,
      elements: this.model.searchResult,
      stylesString: null,
    };
  }

  @computed
  get styleLayers() {
    let result: Map<string, StyleLayer> = new Map();
    // TODO should the individual style layers be @computed or @computedFunc, reference ids changing every time any changes?
    result.set('focusedLine', this.focusedLineStyleLayer);
    result.set('wordSelection', this.wordSelectionStyleLayer);
    result.set('markedLine', this.markedLineStyleLayer);
    result.set('hasDiscussion', this.hasActiveThreadStyleLayer);
    result.set('unfilled', this.unfilledStyleLayer);
    result.set('hasValidationWarning', this.hasValidatorWarningStyleLayer);
    result.set('searchMatch', this.searchFilterStyleLayer);
    return result;
  }

  styleLayersRenderer = new StyleLayersRenderer();

  renderStyleLayers() {
    this.styleLayersRenderer.renderStyleLayers(this.model.episodeKey, this.styleLayers);
  }

  // TODO react to computed styleLayers instead?

  componentWillUnmount() {
    for (const disposer of this.disposers) {
      disposer();
    }
  }

  render() {
    return renderScriptEditorView(this);
  }
}

// [<Emit("$0")>]
// let jsClass(a:'a):'a = jsNative

// // let ScriptEditor = observer(ScriptEditor0)
// // TODO better approach to this
// let ScriptEditor:ReactElement = emitJsStatement observer "return observer(ScriptEditor0)"
