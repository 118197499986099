import { Alert, AlertMessages } from '../../../alert-messages';
import { Element, IdRange, WordGroupDTO } from '../../../basic-types';
import { EKinds } from '../../../elements/element-kinds';
import { ElementList } from '../../../elements/element-list';
import { MutationActions } from '../../db/mutation-actions';

// [<AbstractClass>]
export abstract class WordGroupActions {
  getCurrentWordGroups() {
    return this.content.getKindSubList(EKinds.WORD_GROUP);
  }
  getCurrentSentences() {
    return this.content.getKindSubList(EKinds.SENTENCE);
  }

  focusedWordGroup() {
    if (this.currentFocusedElement && this.currentFocusedElement.kind === EKinds.WORD_GROUP) {
      return this.currentFocusedElement;
    } else {
      return null;
    }
  }

  create(kind, wordRange) {
    if (this.getCurrentWordGroups().hasElementsIntersectWordIdRange(wordRange)) {
      this.alertMessages.add({
        ...Alert,
        text: "can't create word group overlapping with existing word groups",
      });
    } else {
      const sentences = this.getCurrentSentences();
      const sentence1 = sentences.getElementContainingWordId(wordRange.starts);
      const sentence2 = sentences.getElementContainingWordId(wordRange.ends);
      if (sentence1 !== sentence2) {
        this.alertMessages.add({
          ...Alert,
          text: "can't create word group spanning multiple sentences",
        });
      } else {
        const wordGroupData = {
          subKind: kind,
          id: null,
          content: {
            note: '',
            head: '',
          },
          anchors: {
            startWordId: wordRange.starts,
            endWordId: wordRange.ends,
          },
        };
        this.mutationActions.addUpdateWordGroup(wordGroupData);
      }
    }
  }

  update(data: WordGroupDTO) {
    // TODO use an id and DTO?
    const wordRange = { starts: data.anchors.startWordId, ends: data.anchors.endWordId };
    const intersecting0 = this.getCurrentWordGroups().getElementsIntersectWordIdRange(wordRange);
    if (intersecting0) {
      const intersecting = intersecting0.filter((el: Element) => el.id !== data.id);
      if (intersecting.length > 0) {
        this.alertMessages.add({
          ...Alert,
          text: "can't update word group to overlap with other word groups",
        });
        return;
      }
    }
    this.mutationActions.addUpdateWordGroup(data);
  }

  remove(id) {
    this.mutationActions.removeWordGroup(id);
  }

  createFromCurrentWordSelection(kind) {
    const wordRange = this.currentWordSelection;
    if (wordRange) {
      this.create(kind, wordRange);
    }
  }

  removeFocused() {
    const focused = this.focusedWordGroup();
    if (focused) {
      this.remove(focused.id);
    }
  }

  revert(versionObject: any) {
    this.mutationActions.revertWordGroup(versionObject);
  }

  abstract get mutationActions(): MutationActions;
  abstract get content(): ElementList;
  abstract get currentWordSelection(): IdRange;
  abstract get currentFocusedElement(): Element;
  abstract get alertMessages(): AlertMessages;
}
