// open BasicTypes
// open VerbatimActions
// open AppRoot

import { alertMessages, mutationActions, scriptEditorModel } from '../app-root';
import { VerbatimActions } from '../masala-lib/editorial/models/actions/verbatim-actions';

// type VerbatimActions1() =
export class ScriptEditorVerbatimActions extends VerbatimActions {
  get mutationActions() {
    return mutationActions;
  }
  get content() {
    return scriptEditorModel.elements;
  }
  get alertMessages() {
    return alertMessages;
  }
}

// let VerbatimActions():IVerbatimActions = !< VerbatimActions1()
