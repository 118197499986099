// open BasicTypes
// open Fable.Core
// open Fable.Core.JsInterop
// open AppRoot
// open Browser.Types
// open Fable.React
// open ElementList
// open ElementTypes
// open Mobx
// open JSBoilerplate

// type IWordGroupEditor =

import { autorun, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { wordGroupActions } from './app-root';
import { Element, IdRange, WordId } from './masala-lib/basic-types';
import { getSentenceWordIdRange } from './masala-lib/content-funcs';
import { EKinds } from './masala-lib/elements/element-kinds';
import { ElementList } from './masala-lib/elements/element-list';

// let inline renderWordGroupEditorView(data:obj):ReactElement = JsInterop.import "renderView" "./word-group-editor-view.js"
import { renderView as renderWordGroupEditorView } from './word-group-editor-view.js';

// type WordGroupEditor0(initialProps) as s0 =
@observer
export class WordGroupEditor extends React.Component<any> {
  disposers: (() => void)[] = [];

  constructor(props) {
    super(props);
    makeObservable(this);
    this.disposers.push(autorun(() => this.configureEditableValues()));
  }

  get wordGroup(): Element {
    return this.props.element;
  }

  get content(): ElementList {
    return this.props.content;
  }
  get domainWords(): ElementList {
    return this.content.words;
  }

  // TODO factor lookup containing sentences to new module ContentFuncs
  get sentences() {
    return this.content.getKindSubList(EKinds.SENTENCE);
  }

  @computed
  get wordGroupSentence(): Element {
    return this.sentences.getElementContainingWordAddress(this.wordGroup.wordAddress);
  }

  get sentenceWordRange(): IdRange {
    return getSentenceWordIdRange(this.wordGroupSentence, this.domainWords);
  }

  get wordGroups(): ElementList {
    return this.content.getKindSubList(EKinds.WORD_GROUP);
  }

  sentenceTranslation: string = ''; // TODO

  noteInputEl: HTMLElement = null;

  @observable.ref selectedWordRange: IdRange = null;

  @observable.ref noteTextValue: string = '';

  configureEditableValues() {
    let note = this.wordGroup.content['note'];
    note = note ?? '';
    this.noteTextValue = note.replace(/^\s*/, '');
    // TODO factor this to ContentFuncs
    this.selectedWordRange = {
      starts: this.wordGroup.anchors['startWordId'],
      ends: this.wordGroup.anchors['endWordId'],
    };
  }

  revert() {
    this.configureEditableValues();
  }

  getSelectedWordRange() {
    return this.selectedWordRange;
  }

  setSelectedWordRange(range: IdRange) {
    this.selectedWordRange = range;
  }

  handleNoteTextChange(e: Event) {
    this.noteTextValue = e.target['value'];
  }

  focusNoteInput() {
    if (this.noteInputEl) {
      this.noteInputEl.focus();
      // TODO check if works without additional call in settimeout
      setTimeout(() => this.noteInputEl.focus(), 400);
    }
  }

  get valuesChanged(): boolean {
    let edited = false;
    const origNote: string = this.wordGroup.content['note'];
    // TODO factor this to ContentFuncs
    const origRange: IdRange = {
      starts: this.wordGroup.anchors['startWordId'],
      ends: this.wordGroup.anchors['endWordId'],
    };

    if (origNote) {
      if (this.noteTextValue !== origNote.replace(/^\s*/, '')) {
        edited = true;
      }
    } else if (this.noteTextValue.length > 0) {
      edited = true;
    }
    return (
      edited ||
      origRange.starts !== this.selectedWordRange.starts ||
      origRange.ends !== this.selectedWordRange.ends
    );
  }

  handleWordGroupChangeSubmit() {
    const group = this.wordGroup;
    const data = {
      id: group.id,
      subKind: group.subKind,
      content: {
        note: this.noteTextValue,
        head: null,
      },
      anchors: {
        startWordId: <WordId>this.selectedWordRange.starts,
        endWordId: <WordId>this.selectedWordRange.ends,
      },
    };
    wordGroupActions.update(data);
  }

  handleWordGroupDeleteSubmit() {
    wordGroupActions.removeFocused();
  }

  render() {
    return renderWordGroupEditorView(this);
  }
}

// let WordGroupEditor:ReactElement = emitJsStatement observer "return observer(WordGroupEditor0)"
