// open Fable.Core
// open Fable.Core.JsInterop
// open DynamicExtensions
// open BasicTypes
// open ElementList
// open Precedence
// open IndexMapping
// open Singletons
// open JSBoilerplate
// open ElementTypes
// open Mobx
// open AppRoot
// open FilterModel
// open Validations
// open AlertMessages
// open Singletons
// open Browser
// open Browser.Types
// open AlertMessages

import { comparer, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import {
  alertMessages,
  auth,
  metadataBlockActions,
  mutationActions,
  structuralActions,
  translationActions,
  validations,
  verbatimActions,
  wordGroupActions,
} from './app-root';
import { Alert } from './masala-lib/alert-messages';
import {
  Element,
  ElementId,
  ElementKind,
  getKindFromId,
  IdRange,
  isNil,
  isNull,
  notNull,
} from './masala-lib/basic-types';
import {
  BaseFilterKinds,
  byFilter,
  FilterModel,
  FilterTerm,
  openFilter,
  regexTermParser,
  sentenceFilter,
  sicFilter,
  structuralFilter,
  trickyFilter,
  unfilledFilter,
  vocabFilter,
  wordGroupFilter,
} from './masala-lib/editorial/filters/filters-model';
import { ValidatorWarning } from './masala-lib/editorial/validations/validations';
import { EKinds, structuralKinds } from './masala-lib/elements/element-kinds';
import {
  ElementList,
  EmptyElementList,
  SimpleElementList,
} from './masala-lib/elements/element-list';
import { Precedence } from './masala-lib/elements/precedence';
import { deploymentConfig } from './masala-lib/deployment-config';

// let collapseOrder = Precedence([|
export const collapseOrder = new Precedence([
  EKinds.CHAPTER_COMPLETE,
  EKinds.CHAPTER_SUMMARY,
  EKinds.PARAGRAPH_BREAK,
  EKinds.SPEAKER_LABEL,
  EKinds.CULTURAL_NOTE,
  EKinds.SENTENCE,
  EKinds.PASSAGE_HINT,
  EKinds.CHAPTER_TITLE,
  // |])
]);

// let NORMAL = "NORMAL"
export const NORMAL = 'NORMAL';
// let EDITING = "EDITING"
export const EDITING = 'EDITING';
// let LOCKED = "LOCKED"
export const LOCKED = 'LOCKED';
// let DEACTIVATED = "DEACTIVATED"
export const DEACTIVATED = 'DEACTIVATED';
// let CHOICE_MODE = "CHOICE_MODE"
export const CHOICE_MODE = 'CHOICE_MODE';

// type ScriptEditorModel0() as s0 =
export class ScriptEditorModel {
  @observable.ref elements: ElementList = EmptyElementList;

  words: ElementList = EmptyElementList;

  translations: ElementList = EmptyElementList;

  translationLanguage: string = null;

  @observable.ref editEnabled = true;

  @observable.ref collapse = false;

  @observable.ref focusedLineId: ElementId = null;

  @observable.ref wordRangeSelection: IdRange = null;

  @observable.ref editingId: ElementId = null;

  @observable.ref currentChapterTitle = '';

  @observable.ref episodeKey = '';

  @observable.ref markedLineId: ElementId = null;

  @observable.ref kbDeactivated = false;

  getLineEditBuffer: () => any = null;

  @observable.ref choiceModalMode = false;

  @observable.ref choiceModalHtml = '';

  disposers: (() => void)[] = [];

  constructor() {
    makeObservable(this);
    this.disposers.push(
      reaction(
        () => this.focusedLineId,
        () => this.adjustCurrentWordIdForFocusedLineElement()
      )
    );
  }

  setElementList(elements0: ElementList) {
    this.episodeKey = elements0.episodeKey;
    this.words = elements0.words;
    this.elements = elements0;
    this.translations = this.elements.getKindSubList(EKinds.TRANSLATION);
  }

  setTranslationLanguage(language: string) {
    this.translationLanguage = language;
  }

  get wordGroups() {
    return this.elements.getKindSubList(EKinds.WORD_GROUP);
  }

  get sentences() {
    return this.elements.getKindSubList(EKinds.SENTENCE);
  }

  @computed
  get lineElements(): ElementList {
    // TODO remove conditional?
    if (this.elements) {
      // TODO make more generic?
      return this.elements.filter(el => el.kind !== EKinds.WORD_GROUP);
    } else {
      return null;
    }
  }

  @computed
  get visibleElements() {
    // TODO in future lineElements always valid but maybe empty?
    const all = this.lineElements;
    if (all && this.collapse) {
      return all.filter(collapseOrder.precedenceAtLeastFilter(EKinds.PASSAGE_HINT));
    } else {
      return all;
    }
  }

  toggleCollapseExpand() {
    this.collapse = !this.collapse;
  }

  setEditingId(id) {
    this.editingId = id;
  }

  get isEditing(): boolean {
    return !!this.editingId;
  }

  get isVerbatimEditing() {
    // TODO getter? computed?
    return false;
  }

  @computed
  get mode() {
    if (this.kbDeactivated) {
      return DEACTIVATED;
    } else if (this.choiceModalMode) {
      return CHOICE_MODE;
    } else if (!this.editEnabled) {
      return LOCKED;
    } else if (this.isEditing) {
      return EDITING; // TODO change to some kind of enum or constants
    } else {
      return NORMAL;
    }
  }

  setGetLineEditBuffer(f) {
    this.getLineEditBuffer = f;
  }

  saveContent(id: ElementId, content: any) {
    const kind = getKindFromId(id);
    if (structuralKinds.includes(kind)) {
      structuralActions.updateContent(id, content);
    } else if (kind === EKinds.SENTENCE) {
      // TODO resolve difference in type of content param versus even though they are actually both strings??
      return verbatimActions.updateSentence(id, content);
    } else if (kind === EKinds.TRANSLATION) {
      const translationElement = this.elements.getElement(id);
      // TODO typing?
      const elementId = translationElement['elementId'];
      const language = translationElement['translationLanguage'];
      translationActions.addUpdate(elementId, language, content);
    } else if (kind === EKinds.METADATA_BLOCK) {
      metadataBlockActions.updateContent(id, content);
    }
  }

  saveLineEdit() {
    if (this.getLineEditBuffer) {
      const content = this.getLineEditBuffer();
      this.saveContent(this.editingId, content);
      this.setEditingId(null);
    }
  }

  abortLineEdit() {
    this.setEditingId(null);
  }

  cancelSelections() {
    this.markedLineId = null;
    this.wordRangeSelection = null;
    // TDDO more?
  }

  setFocusedLineId(id) {
    this.focusedLineId = id;
    if (this.isEditing && this.focusedLineId !== this.editingId) {
      this.saveLineEdit();
    }
  }

  normalizeWordRange(range) {
    if (range) {
      const startIndex = this.words.getIndex(range.starts);
      const endIndex = this.words.getIndex(range.ends);
      if (startIndex > endIndex) {
        return { starts: range.ends, ends: range.starts };
      } else {
        return range;
      }
    } else {
      return range;
    }
  }

  setWordRangeSelection(range0: IdRange) {
    const range = this.normalizeWordRange(range0);
    if (!comparer.shallow(range, this.wordRangeSelection)) {
      runInAction(() => {
        if (range) {
          const shouldFocusSentence = this.sentences.getElementContainingWordId(range.starts);
          const shouldFocusLineId = shouldFocusSentence ? shouldFocusSentence.id : null;
          this.setFocusedLineId(shouldFocusLineId);
        }
        this.wordRangeSelection = range;
      });
    }
  }

  setCurrentWordId(id) {
    if (isNull(id)) {
      // TODO isNull does not really tell null and 0 apart
      this.wordRangeSelection = null;
    }
    this.setWordRangeSelection({ starts: id, ends: id });
  }

  wordRangeSelectTo(id) {
    if (this.wordRangeSelection) {
      this.setWordRangeSelection({ starts: this.wordRangeSelection.starts, ends: id });
    } else {
      this.setCurrentWordId(id);
    }
  }

  setFocusedElementId(id) {
    if (id) {
      const kind = getKindFromId(id);
      if (kind === EKinds.WORD_GROUP) {
        const group = this.elements.getElement(id);
        // TODO typing?
        this.setCurrentWordId(group.anchors['startWordId']);
      } else if (kind === EKinds.WORD) {
        this.setCurrentWordId(id);
      } else if (kind === EKinds.SENTENCE) {
        this.setCurrentWordId(null);
        this.focusedLineId = id;
      } else {
        this.focusedLineId = id;
      }
    }
  }

  get focusedLineElement() {
    // TODO remove conditional
    return this.elements ? this.elements.getElement(this.focusedLineId) : null;
  }

  @computed
  get currentWordId() {
    if (this.wordRangeSelection) {
      return this.wordRangeSelection.starts;
    } else {
      // TODO check ambiguity at usages for Null for wordId 0
      return null;
    }
  }

  @computed
  get focusedElementId(): ElementId {
    const focusedWordId = this.currentWordId;
    if (focusedWordId) {
      const focusedWordGroup = this.wordGroups.getElementContainingWordId(focusedWordId);
      if (focusedWordGroup) {
        return focusedWordGroup.id;
      } else {
        return this.focusedLineId;
      }
    } else {
      return this.focusedLineId;
    }
  }

  get focusedElement(): Element {
    // TODO take out conditional?
    return this.elements ? this.elements.getElement(this.focusedElementId) : null;
  }

  adjustCurrentWordIdForFocusedLineElement() {
    const focused = this.focusedLineElement;
    if (focused && this.currentWordId) {
      if (focused.kind !== EKinds.SENTENCE) {
        this.wordRangeSelection = null; // TODO change to setCurrentWordId when null ambiguity issue resolved
      } else if (!(focused === this.sentences.getElementContainingWordId(this.currentWordId))) {
        this.wordRangeSelection = null;
      }
    }
  }

  searchResultCursorPointId(): ElementId {
    if (
      this.focusedElementId &&
      getKindFromId(this.focusedElementId) === EKinds.SENTENCE &&
      this.currentWordId
    ) {
      return this.currentWordId;
    } else {
      return this.focusedElementId;
    }
  }

  cursorLineUp() {
    const next = this.visibleElements.prevId(this.focusedLineId);
    if (next) {
      this.focusedLineId = next;
    }
  }

  cursorLineDown() {
    const next = this.visibleElements.nextId(this.focusedLineId);
    if (next) {
      this.focusedLineId = next;
    }
  }

  editFocusedLine() {
    this.setEditingId(this.focusedLineId);
  }

  markFocusedLine() {
    if (this.focusedLineId) {
      const kind = getKindFromId(this.focusedLineId);
      if (structuralKinds.includes(kind)) {
        this.markedLineId = this.focusedLineId;
      }
    }
  }

  get markedLineElement() {
    return this.markedLineId ? this.elements.getElement(this.markedLineId) : null;
  }

  moveMarked() {
    if (this.markedLineId && this.focusedLineId) {
      structuralActions.move(this.markedLineId, this.focusedLineId);
      this.markedLineId = null;
    }
  }

  createWordGroupWithWordSelection(kind) {
    wordGroupActions.createFromCurrentWordSelection(kind);
  }

  createStucturalAtFocusedLine(kind: ElementKind) {
    const newId = structuralActions.create(kind, this.focusedLineId);
    this.setFocusedLineId(newId);
    this.editFocusedLine();
  }

  createOrEditTranslationAtFocusedLine() {
    if (this.focusedLineId) {
      const kind = getKindFromId(this.focusedLineId);
      if (kind === EKinds.TRANSLATION) {
        this.editFocusedLine();
      } else {
        const translationId = translationActions.getTranslationElementId(
          this.focusedLineId,
          this.translationLanguage
        );
        const translation = this.translations.getElement(translationId);
        if (translation) {
          this.setFocusedLineId(translationId);
          this.editFocusedLine();
        } else {
          this.setFocusedLineId(
            translationActions.addUpdate(this.focusedLineId, this.translationLanguage, '')
          );
          this.editFocusedLine();
        }
      }
    }
  }

  removeFocused() {
    // TODO make isStructural?
    if (this.focusedLineId) {
      const kind = getKindFromId(this.focusedLineId);
      if (structuralKinds.includes(kind)) {
        structuralActions.remove(this.focusedLineId);
      } else if (kind === EKinds.SENTENCE) {
        verbatimActions.removeSentence(this.focusedLineId);
      } else if (kind === EKinds.METADATA_BLOCK) {
        metadataBlockActions.remove(this.focusedLineId);
      }
    }
  }

  // TODO generalize with step direction?
  nextSearchResult() {
    const cursorPointId = this.searchResultCursorPointId();
    let nextResultId: ElementId = null;
    const currentResults = this.searchResult;
    if (cursorPointId) {
      nextResultId = currentResults.nextId(cursorPointId, true);
    } else if (this.searchResult.elements.length > 0) {
      nextResultId = currentResults.elements[0].id;
    }

    this.setFocusedElementId(nextResultId);
  }

  prevSearchResult() {
    // TODO factor with above
    const cursorPointId = this.searchResultCursorPointId();
    let prevResultId: ElementId = null;
    const currentResults = this.searchResult;
    if (cursorPointId) {
      prevResultId = currentResults.prevId(cursorPointId, true);
    } else if (this.searchResult.elements.length > 0) {
      prevResultId = currentResults.elements[0].id;
    }
    this.setFocusedElementId(prevResultId);
  }

  splitSentence(direction) {
    const splitWordId = this.currentWordId;
    if (splitWordId) {
      const sentence = this.focusedLineElement;
      if (sentence.kind === EKinds.SENTENCE) {
        verbatimActions.splitSentence(sentence.id, splitWordId, direction);
      }
    }
  }

  createMetadataUrlBlock() {
    // TODO move to metadataBlockActions?
    const blocks = this.elements.getKindSubList(EKinds.METADATA_BLOCK).elements;
    const metadataUrlBlock = blocks.find((b: Element) => b.subKind === EKinds.METADATA_URL);
    if (metadataUrlBlock) {
      alertMessages.add({ ...Alert, text: 'cannot add more than one METADATA URL' });
    } else {
      metadataBlockActions.create(EKinds.METADATA_URL);
    }
  }

  async copyMetadataToClipboard() {
    // TODO move to metadataBlockActions?
    let lines = [];

    const prefixes: any = {
      [EKinds.NOTES]: '/**! NOTES\n',
      [EKinds.METADATA]: '/**! METADATA\n',
      [EKinds.ASSET_LINKS]: '/**! ASSET-LINKS\n',
      [EKinds.METADATA_URL]: '/**! METADATA URL\n',
      [EKinds.CAST]: '/**! CAST\n',
    };
    const blocks = this.elements.getKindSubList(EKinds.METADATA_BLOCK).elements;
    for (const block of blocks) {
      lines.push(prefixes[block.subKind]);
      lines.push(block.content);
      lines.push('*/\n\n');
    }

    const output = lines.join('\n');
    const permission = await navigator.permissions.query({ name: 'clipboard-write' });
    return await navigator.clipboard.writeText(output);
  }

  async copySharableElementLinkToClipboard() {
    // @jrw, either form can be used, which do you prefer?
    // let baseUrl = Utils.getDeploymentConfig("scriptEditorUrl")
    let baseUrl = deploymentConfig.scriptEditorUrl;
    let target = this.focusedElementId ?? this.currentWordId;
    if (isNil(target)) {
      return;
    }
    if (getKindFromId(target) === EKinds.SENTENCE && this.currentWordId) {
      target = this.currentWordId;
    }
    const url = `${baseUrl}/episodes/${this.episodeKey}/${target}`;
    // TODO figure out why have to make the PermissionsDescriptor an anonymous record when already have the record type
    const permission = await navigator.permissions.query({ name: 'clipboard-write' });
    return await navigator.clipboard.writeText(url);
  }

  @computed
  get validationWarnings() {
    const els: Element[] = [];
    for (const warning of validations.activeWarnings) {
      els.push(this.elements.getElement(warning.elementId));
    }

    return SimpleElementList(els);
  }

  revertToVersion(versionObject: any) {
    const kind = versionObject.kind;

    if (kind === EKinds.WORD_GROUP) {
      wordGroupActions.revert(versionObject);
    } else if (kind === EKinds.TRANSLATION) {
      translationActions.revert(versionObject);
    } else if (kind === EKinds.SENTENCE) {
      alertMessages.add({ ...Alert, text: 'revert of sentences not yet supported' });
    } else if (structuralKinds.includes(kind)) {
      structuralActions.revert(versionObject);
    }
  }

  get focusedElementValidatorWarning(): ValidatorWarning {
    const focusedId = this.focusedElementId;
    // TODO optimize the whole warning lookup thing
    if (notNull(focusedId)) {
      return validations.activeWarnings.find(
        (warn: ValidatorWarning) => warn.elementId === focusedId
      );
    } else {
      return null;
    }
  }

  setFocusedWarningSuppression(suppress: boolean) {
    if (this.focusedElementId) {
      mutationActions.setElementWarningSuppression(this.focusedElementId, suppress);
    }
  }

  warningFilter = {
    kind: BaseFilterKinds.WARNING,
    isFlag: true,
    canonicalText: t => '#warning ',
    parse: regexTermParser(BaseFilterKinds.WARNING, /#warning/),
    // TODO optimize lookup
    func: (term: FilterTerm) => (el: Element) => !!this.validationWarnings.getElement(el.id),
  };

  selfAssigned(el: Element) {
    return el['thread'].assignee === auth.appUser.id;
  }

  selfParticipant(el: Element) {
    const currentUserId: string = auth.appUser.id;
    const participants: string[] = el['thread'].participants;
    if (participants) {
      return participants.includes(currentUserId);
    } else {
      return false;
    }
  }

  assignedToSelfFilter = {
    kind: BaseFilterKinds.ASSIGNED,
    isFlag: true,
    canonicalText: t => '#assigned ',
    parse: regexTermParser(BaseFilterKinds.ASSIGNED, /#assigned/),
    // TODO is there no interface for our user type?
    func: (term: FilterTerm) => (el: Element) => this.selfAssigned(el),
  };

  areParticipantFilter = {
    kind: BaseFilterKinds.AREPARTICIPANT,
    isFlag: true,
    canonicalText: t => '#areparticipant ',
    parse: regexTermParser(BaseFilterKinds.AREPARTICIPANT, /#areparticipant/),
    // TODO is there no interface for our user type?
    func: (term: FilterTerm) => (el: Element) => this.selfParticipant(el),
  };

  filters: FilterModel = new FilterModel([
    wordGroupFilter,
    vocabFilter,
    trickyFilter,
    sicFilter,
    sentenceFilter,
    structuralFilter,
    unfilledFilter,
    openFilter,
    byFilter,
    this.warningFilter,
    this.assignedToSelfFilter,
    this.areParticipantFilter,
  ]);

  @computed
  get searchResult() {
    return this.elements.filter(this.filters.filterFunction);
  }

  setKbDeactivated(value: boolean) {
    this.kbDeactivated = value;
  }

  setChoiceModalMode(choicesHtml) {
    if (choicesHtml) {
      runInAction(() => {
        this.choiceModalMode = true;
        this.choiceModalHtml = choicesHtml;
      });
    } else {
      this.choiceModalMode = false;
    }
  }
}

// let ScriptEditorModel():IScriptEditorModel = !< ScriptEditorModel0()
